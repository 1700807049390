import { useNavigate } from "react-router-dom";
// import { useSelector } from "react-redux";
import { Button } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";

import { useEffect } from "react";
import optimizeImageSize from "../../../../utils/optimizeImageSize";
import { productsActionCreators } from "../../../../redux/products";
import formatter from "../../../../utils/formatter";

const Bestseller = ({ products }) => {
  const navigate = useNavigate();

  // const { type } = useSelector((state) => state.user);
  const { data: currencyData } = useSelector((state) => state.currency);

  const { homepageProducts } = useSelector((state) => state.products);

  const dispatch = useDispatch();

  const { fetchMenHomepageProductsAction } = bindActionCreators(
    productsActionCreators,
    dispatch
  );

  useEffect(() => {
    fetchMenHomepageProductsAction();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="bestseller-container">
      <h1 className="bestseller-title">Our Bestsellers</h1>

      <div className="bestseller-products-container">
        {homepageProducts?.bestsellers?.map((product) => (
          <div
            className="bestseller-product"
            key={product.img1}
            onClick={() => navigate(`/designers/${product?.store_slug}`)}
          >
            <img
              className="bestseller-product-image"
              loading="lazy"
              alt="product"
              src={optimizeImageSize(product.img1)}
            />
            <div
              style={{ textAlign: "left", fontFamily: "DomaineSansText-Light" }}
            >
              <div className="bestseller-product-title">
                {product.store_name}
              </div>
              <div className="bestseller-product-subtitle">{product.name}</div>
            </div>
            <div className="bestseller-btn-price-contain">
              <Button
                className="bestseller-btn"
                // onClick={() => shopNewIn()}
              >
                Buy Now
              </Button>
              <div className="bestseller-product-price">
                {formatter(
                  product?.price,
                  currencyData?.rate,
                  currencyData?.code,
                  currencyData?.indicator
                )}
              </div>
            </div>
          </div>
        ))}
      </div>

      <style jsx="true">{`
        .bestseller-container {
          margin: 24px auto;
          padding: 30px 100px 40px;
          background: #f9f9f9;
        }
        .bestseller-container .bestseller-title {
          font-family: "DomaineSansDisplay-SemiBold";
          font-size: 30px;
          text-align: center;
          line-height: 26px;
          margin: 0 auto 24px;
        }
        .bestseller-products-container {
          display: grid;
          grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(
              0,
              1fr
            );
          gap: 24px;
          justify-content: center;
          text-align: center;
        }
        .bestseller-product {
          background: #fff;
          border-radius: 8px;
          padding: 16px;
          cursor: pointer;
          transition: all 0.3s ease;
        }
        .bestseller-product-image {
          width: 100%;
          height: auto;
          aspect-ratio: 11/16;
          border-top-left-radius: 8px;
          border-top-right-radius: 8px;
        }
        .bestseller-product-title {
          font-size: 14px;
          font-family: DomaineSansText-Regular;
          line-height: 1;
          margin: 10px 0px 4px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
        }
        .bestseller-product-subtitle {
          font-size: 12px;
          font-family: DomaineSansText-Regular;
          color: #6f6f6f;
          line-height: 1;
          margin: 0px 0px 4px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
        }
        .bestseller-product-price {
          font-size: 14px;
          font-family: DomaineSansText-Medium;
          color: #000;
        }
        .bestseller-btn-price-contain {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 16px;
        }
        .bestseller-btn {
          width: auto;
          height: auto;
          padding: 8px 20px;
          background: #000000;
          border-radius: 2px;
          color: #fff;
          font: normal normal 14px DomaineSansText-Regular;
        }
      `}</style>
    </div>
  );
};

export default Bestseller;
