
import { Divider, Modal, Switch } from "antd";
import { useMediaQuery } from "react-responsive";
import { CloseCircleOutlined } from "@ant-design/icons";
import { useState } from "react";

const SizeGuideModalWeb = ({
    sizeGuideModalVisible,
    setSizeGuideModalVisible,
    version,
    designerName,
    productName,
    productImage,
    productSubcategory
}) => {

    const [size, setSize] = useState(0)
    const [shoeSize, setShoeSize] = useState(0)
    const [measureInches, setMeasureInches] = useState(false)

    const isTabletOrMobile = useMediaQuery({ maxWidth: 640 });

    const womenClothing =[
        { size:"x-small", bust: "78", waist:"60", hip:"83.5",uk:"4",eu:'32',us:"0" },
        { size:"small", bust: "80.5", waist:"62.5", hip:"86", uk:"6", eu:'34', us:"2" },
        { size:"medium-8", bust: "83", waist:"65", hip:"88.5", uk:"8", eu:'36', us:"4" },
        { size:"medium-10", bust: "88", waist:"70", hip:"93.5", uk:"10", eu:'38', us:"6" },
        { size:"medium-12", bust: "93", waist:"75", hip:"98.5", uk:"12", eu:'40', us:"8" },
        { size:"large", bust: "98", waist:"80", hip:"103.5", uk:"14", eu:'42', us:"10" },
        { size:"x-large", bust: "103", waist:"85", hip:"108.5", uk:"16", eu:'44', us:"12" },
        { size:"xxl", bust: "110.5", waist:"92.5", hip:"116", uk:"18", eu:'46', us:"14" }
    ]

    const womenJeansClothing =[
        { size:"xxs", bust: "73.5", waist:"55.5", hip:"80",uk:"2",eu:'30',us:"0" },
        { size:"x-small", bust: "76", waist:"58", hip:"82.5",uk:"4",eu:'32',us:"0" },
        { size:"small", bust: "78.5", waist:"60.5", hip:"85", uk:"6", eu:'34', us:"2" },
        { size:"medium-8", bust: "81", waist:"63", hip:"68", uk:"8", eu:'36', us:"4" },
        { size:"medium-10", bust: "86", waist:"68", hip:"92.5", uk:"10", eu:'38', us:"6" },
        { size:"medium-12", bust: "91", waist:"73", hip:"97.5", uk:"12", eu:'40', us:"8" },
        { size:"large", bust: "96", waist:"78", hip:"102.5", uk:"14", eu:'42', us:"10" },
        { size:"x-large", bust: "101", waist:"83", hip:"107.5", uk:"16", eu:'44', us:"12" },
    ]

    const womenShoes=[
        { size:"UK 2", footlength:"212", us:'4', eu:'35' },
        { size:"UK 3", footlength:"220", us:'5', eu:'36' },
        { size:"UK 4", footlength:"229", us:'6', eu:'37' },
        { size:"UK 5", footlength:"237", us:'7', eu:'38' },
        { size:"UK 6", footlength:"246", us:'8', eu:'39' },
        { size:"UK 7", footlength:"254", us:'9', eu:'40' },
        { size:"UK 8", footlength:"262", us:'10', eu:'41' },
        { size:"UK 9", footlength:"270", us:'11', eu:'42' }
    ]

    const menShoes=[
        { size:"UK 3", footlength:"none", us:'4', eu:'35.5' },
        { size:"UK 4", footlength:"none", us:'5', eu:'37' },
        { size:"UK 5", footlength:"237", us:'6', eu:'38' },
        { size:"UK 6", footlength:"246", us:'7', eu:'39' },
        { size:"UK 7", footlength:"254", us:'8', eu:'40.5' },
        { size:"UK 7.5", footlength:"258", us:'8.5', eu:'41' },
        { size:"UK 8", footlength:"262", us:'9', eu:'42' },
        { size:"UK 8.5", footlength:"266", us:'9.5', eu:'42.5' },
        { size:"UK 9", footlength:"271", us:'10', eu:'43' },
        { size:"UK 9.5", footlength:"275", us:'10.5', eu:'44' },
        { size:"UK 10", footlength:"279", us:'11', eu:'44.5' },
        { size:"UK 10.5", footlength:"283", us:'11.5', eu:'45' },
        { size:"UK 11", footlength:"288", us:'12', eu:'46' },
        { size:"UK 12", footlength:"296", us:'13', eu:'47' },
        { size:"UK 13", footlength:"305", us:'14', eu:'48' },
        { size:"UK 14", footlength:"314", us:'15', eu:'49.5' }
    ]

    const menClothing =[
        { size:"xs", chest: "90 - 93", waist:"78 - 81", hip:"94 -97", height: "168 - 173", uk:"36",eu:'46',us:"36" },
        { size:"small", chest: "94 - 97", waist:"82 - 85", hip:"98 - 101",height: "171 - 176", uk:"38", eu:'48', us:"38" },
        { size:"medium", chest: "98 - 101", waist:"86 -89", hip:"102 - 105",height: "174 - 179", uk:"40", eu:'50', us:"40" },
        { size:"large", chest: "102 -105", waist:"90 - 94", hip:"106 - 109",height: "177 - 182 ", uk:"42", eu:'52', us:"42" },
        { size:"x-large", chest: "106 - 109", waist:"95 - 99", hip:"110 -113",height: "180 -184", uk:"44", eu:'54', us:"44" },
        { size:"xxl", chest: "10 - 113", waist:"100 - 104", hip:"114 - 117",height: "182 - 186", uk:"46", eu:'56', us:"46" },
        { size:"3XL", chest: "114 - 117", waist:"105 - 109", hip:"118 - 121 ",height: "184 - 188", uk:"48", eu:'58', us:"48" },
        { size:"3XL", chest: "118 - 121", waist:"110 - 114", hip:"122 - 125",height: "184 - 188", uk:"50", eu:'60', us:"50" },
        { size:"4XL", chest: "122 - 125", waist:"115 - 119", hip:"126 - 129",height: "187 - 190", uk:"52", eu:'62', us:"52" },
        { size:"4XL", chest: "126 - 128", waist:"120 - 124", hip:"-",height: "191 - 192", uk:"54", eu:'64', us:"54" },
        { size:"5XL", chest: "129 - 132", waist:"125 -128", hip:"-",height: "193 -194 ", uk:"56", eu:'66', us:"56" },
        { size:"5XL", chest: "133 - 136", waist:"129 - 132", hip:"-",height: "195 - 196", uk:"58", eu:'68', us:"58" },
        { size:"6XL", chest: "137 - 140", waist:"133 -134", hip:"-",height: "197 - 198", uk:"60", eu:'70', us:"60" },
        { size:"6XL", chest: "141 - 144", waist:"135 - 138", hip:"-",height: "199 - 200", uk:"62", eu:'72', us:"62" },
        { size:"7XL", chest: "145 - 148", waist:"139 - 142", hip:"-",height: "-", uk:"64", eu:'74', us:"64" },
    ]

    const menFitWaist =[
        { size:"26", cm:"66" },
        { size:"28", cm:"71" },
        { size:"29", cm:"73.5" },
        { size:"30", cm:"76" },
        { size:"31", cm:"78.5" },
        { size:"32", cm:"81"},
        { size:"33", cm:"83.5" },
        { size:"34", cm:"86" },
        { size:"36", cm:"91" },
        { size:"38", cm:"96" },
        { size:"40", cm:"101" },
    ]

    const ringSizes =[
        { size:"xs", diameter: "0.17", circumference:"0.54", },
        { size:"s", diameter: "0.18", circumference:"0.57", },
        { size:"m", diameter: "0.19", circumference:"0.6", },
        { size:"l", diameter: "0.2", circumference:"0.63", },
        { size:"xl", diameter: "0.21", circumference:"0.66", },
    ]

    return (
    <>
        <Modal
        centered
        open={sizeGuideModalVisible}
        footer={null}
        width={!isTabletOrMobile?"90%":"98%"}
        onCancel={() => {
            setSizeGuideModalVisible(false);
        }}
        closeIcon={
            <CloseCircleOutlined
            style={{
                color: "#000",
                padding: "15px 10px 0 0",
                fontSize: "25px",
            }}
            />
        }
        transitionName=""
        style={{ opacity: "100%", padding: "40px 0" }}
        // maskClosable={false}
        className='size-guide-modal'
        >

            <div>
                {version==="womenGuide" && <div>WOMEN'S SIZE GUIDE {`(${productSubcategory === "footwear"? "SHOES" : productSubcategory === "accessories" ? "ACCESSORIES" : "CLOTHINGS" })`}</div>}
                {version==="womenJeansGuide" && <div>WOMEN'S SIZE GUIDE (JEANS/TROUSER AND SHOES)</div>}
                {version==="menJeansGuide" && <div>MEN'S SIZE GUIDE (JEANS/TROUSER AND SHOES)</div>}
                {version==="menGuide" && <div>MEN'S SIZE GUIDE (CLOTHING AND SHOES)</div>}
                <Divider style={{background:"#444444"}} />

                {/* clothing size guide */}
                {
                productSubcategory !== "footwear" &&
                productSubcategory !== "accessories" &&
                <>
                <div className="cart-page-bag-section-body">
             
                <div className="cart-page-bag-section-body-item" >
                  <div className="cart-page-bag-section-body-item-main">
                    <div
                      className="size-guide-product-section-image"
                      style={{
                        backgroundImage: `url(${productImage?.imageURL})`,
                      }}
                    ></div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                      }}
                    >
                      <div className="size-guide-product-section">
                        <div style={{fontWeight: "bold", fontFamily: 'DomaineSansText-Regular'}}> {designerName} </div>
                        <div> {productName} </div>
                        <div>
                            <ul style={{fontSize:'12px', paddingInlineStart:'16px', display:'flex', flexDirection:'column', gap:'4px'}}>
                                <li>Fits true to size, however those who are between sizes should take the larger size</li>
                                <li>Cut to be fitted at the bust and waist, loose at the hip</li>
                                <li>Mid-weight, non-stretchy fabric</li>
                                <li>Model is 177cm/ 5'10" and is wearing an IT 40</li>
                            </ul>
                        </div>
                      </div>
                      
                    </div>
                  </div>
                 
                </div>
                </div>
                <Divider style={{background:"#444444"}} />
                {version==="womenGuide" && <div>
                <div>PRODUCT MEASUREMENTS</div>
                <div style={{fontSize:'12px', marginBottom:"8px"}}>Use the conversion chart below to find your size</div>
                <div style={{overflowX:'scroll'}}>
                <div style={{display:'flex', borderRight:'1px solid black', width:"fit-content"}}>
                    <div style={{width:!isTabletOrMobile?"300px":"120px"}}>
                        <div className='size-guide-table-title-cell cm-inch-container'>
                            <span>cm</span>
                            <Switch onClick={()=>setMeasureInches(!measureInches)} />
                            <span>inch</span>
                        </div>
                        
                        <div className='size-guide-table-title-cell'>Bust</div>
                        <div className='size-guide-table-title-cell'>Waist</div>
                        <div className='size-guide-table-title-cell'>Hip</div>
                    </div>
                   
                   { womenClothing.map((data, index)=>(
                        <div className='size-guide-table-title-column' key={index} onClick={()=>setSize(index)} style={{border:size===index?"1px solid black":"none"}} >
                        <div className='size-guide-table-title-cell' style={{textAlign:'center', height:"70px", borderTop:'1px solid black'}}>
                            <div className="size-guide-table-cell-circle" style={{border:size===index?"5px solid black":"1px solid black"}}></div>
                            <div> {data?.size} </div>
                        </div>
                        
                        <div className='size-guide-table-title-cell'> {!measureInches ? data?.bust : Math.round(data?.bust *0.39370) } </div>
                        <div className='size-guide-table-title-cell'> {!measureInches ? data?.waist : Math.round(data?.waist *0.39370) } </div>
                        <div className='size-guide-table-title-cell'> {!measureInches ? data?.hip : Math.round(data?.hip *0.39370) }  </div>
                        </div>
                    ))}
                   
                   
               </div>
                </div>
                </div>}

                {version==="womenJeansGuide" && <div>
                <div>PRODUCT MEASUREMENTS</div>
                <div style={{fontSize:'12px', marginBottom:"8px"}}>Use the conversion chart below to find your size</div>
                <div style={{overflowX:'scroll'}}>
                <div style={{display:'flex', borderRight:'1px solid black', width:"fit-content"}}>
                    <div style={{width:!isTabletOrMobile?"300px":"120px"}}>
                        <div className='size-guide-table-title-cell cm-inch-container'>
                            <span>cm</span>
                            <Switch onClick={()=>setMeasureInches(!measureInches)} />
                            <span>inch</span>
                        </div>
                        
                        <div className='size-guide-table-title-cell'>Bust</div>
                        <div className='size-guide-table-title-cell'>Waist</div>
                        <div className='size-guide-table-title-cell'>Hip</div>
                    </div>
                   
                   { womenJeansClothing.map((data, index)=>(
                        <div className='size-guide-table-title-column' key={index} onClick={()=>setSize(index)} style={{border:size===index?"1px solid black":"none"}} >
                        <div className='size-guide-table-title-cell' style={{textAlign:'center', height:"70px", borderTop:'1px solid black'}}>
                            <div className="size-guide-table-cell-circle" style={{border:size===index?"5px solid black":"1px solid black"}}></div>
                            <div> {data?.size} </div>
                        </div>
                        
                        <div className='size-guide-table-title-cell'> {!measureInches ? data?.bust : Math.round(data?.bust *0.39370) }  </div>
                        <div className='size-guide-table-title-cell'> {!measureInches ? data?.waist : Math.round(data?.waist *0.39370) }  </div>
                        <div className='size-guide-table-title-cell'> {!measureInches ? data?.hip : Math.round(data?.hip *0.39370) }  </div>
                        </div>
                    ))}
                   
                   
               </div>
                </div>
                </div>}

                {version==="menGuide" && <div>
                <div>CLOTHING MEASUREMENTS</div>
                <div style={{fontSize:'12px', marginBottom:"8px"}}>Use the conversion chart below to find your size</div>
                <div style={{overflowX:'scroll'}}>
                <div style={{display:'flex', borderRight:'1px solid black', width:"fit-content"}}>
                    <div style={{width:!isTabletOrMobile?"300px":"120px"}}>
                        <div className='size-guide-table-title-cell cm-inch-container'>
                            <span>cm</span>
                            <Switch onClick={()=>setMeasureInches(!measureInches)} />
                            <span>inch</span>
                        </div>
                        
                        <div className='size-guide-table-title-cell'>Chest</div>
                        <div className='size-guide-table-title-cell'>Waist</div>
                        <div className='size-guide-table-title-cell'>Hip Circumference</div>
                        <div className='size-guide-table-title-cell'>Height</div>
                    </div>
                   
                    {menClothing.map((data, index)=>(
                        <div className='size-guide-table-title-column' key={index} onClick={()=>setSize(index)} style={{border:size===index?"1px solid black":"none"}} >
                        <div className='size-guide-table-title-cell' style={{textAlign:'center', height:"70px", borderTop:'1px solid black'}}>
                            <div className="size-guide-table-cell-circle" style={{border:size===index?"5px solid black":"1px solid black"}}></div>
                            <div> {data?.size} </div>
                        </div>
                        
                        <div className='size-guide-table-title-cell'> {!measureInches ? data?.chest : Math.round(data?.chest *0.39370) }  </div>
                        <div className='size-guide-table-title-cell'> {!measureInches ? data?.waist : Math.round(data?.waist *0.39370) } </div>
                        <div className='size-guide-table-title-cell'> {!measureInches ? data?.hip : Math.round(data?.hip *0.39370) }  </div>
                        <div className='size-guide-table-title-cell'> {!measureInches ? data?.height : Math.round(data?.height *0.39370) }  </div>
                        </div>
                    ))}
                   
                   
               </div>
                </div>
                </div>}

                {version==="menJeansGuide" && <div>
                <div>CLOTHING MEASUREMENTS</div>
                <div style={{fontSize:'12px', marginBottom:"16px"}}>Use the conversion chart below to find your size</div>
                <div style={{fontWeight:'bold'}}>TO FIT WAIST SIZE</div>
                <div style={{overflowX:'scroll'}}>
                <div style={{display:'flex', borderRight:'1px solid black', width:"fit-content"}}>
                    <div style={{width:!isTabletOrMobile?"300px":"120px"}}>
                        <div className='size-guide-table-title-cell cm-inch-container'>
                            <span>cm</span>
                            <Switch onClick={()=>setMeasureInches(!measureInches)} />
                            <span>inch</span>
                        </div>
                        
                        <div className='size-guide-table-title-cell'>cm</div>
                    </div>
                   
                    {menFitWaist.map((data, index)=>(
                        <div className='size-guide-table-title-column' key={index} onClick={()=>setSize(index)} style={{border:size===index?"1px solid black":"none"}} >
                        <div className='size-guide-table-title-cell' style={{textAlign:'center', height:"70px", borderTop:'1px solid black'}}>
                            <div className="size-guide-table-cell-circle" style={{border:size===index?"5px solid black":"1px solid black"}}></div>
                            <div> {data?.size} </div>
                        </div>
                        
                        <div className='size-guide-table-title-cell'> {!measureInches ? data?.cm : Math.round(data?.cm *0.39370) }  </div>
                        </div>
                    ))}
                   
                   
               </div>
                </div>

                <Divider style={{background:"#444444"}} />
                <div style={{fontWeight:'bold'}}>TO FIT INSIDE LENGTH </div>
                <div style={{overflowX:'scroll'}}>
                <table className="to-fit-table">
                    <tr>
                        <th>SIZE</th>
                        <th>INCHES</th>
                        <th>CM</th>
                    </tr>
                    <tr>
                        <td>Short</td>
                        <td>30</td>
                        <td>76</td>
                    </tr>
                    <tr>
                        <td>Regular</td>
                        <td>32</td>
                        <td>81</td>
                    </tr>
                    <tr>
                        <td>Long</td>
                        <td>34</td>
                        <td>86</td>
                    </tr>
                </table>
                </div>
                </div>}

                <Divider style={{background:"#444444"}} />
                <div>SIZE CONVERSION</div>
                <div style={{fontSize:'12px', marginBottom:"8px"}}>Use the conversion chart below to find your size</div>
                <div style={{overflowX:'scroll'}}>
                <div style={{display:'flex', width:"fit-content", gap:"8px"}}>
                   
                   {version==="womenGuide" &&
                    womenClothing.map((data, index)=>(
                        <div key={index} onClick={()=>setSize(index)} style={{border:size===index?"2px solid black":"1px solid grey", padding:'20px 10px 10px', minWidth:'160px'}} >
                        <div>
                            <div className="size-guide-table-cell-circle" style={{border:size===index?"5px solid black":"1px solid black", margin:0}}></div>
                            <div> {data?.size} </div>
                        </div>
                        <div>is equivalent to a</div>
                        <div>UK {data?.uk} </div>
                        <div style={{width:"16px", borderTop:'1px solid black', height:'1px', margin:'10px 0'}}></div>
                        <div>
                            <div style={{display:'flex', justifyContent:'space-between'}}>
                                <div>EU</div>
                                <div>{data?.eu}</div>
                            </div>
                            <div style={{display:'flex', justifyContent:'space-between'}}>
                                <div>US</div>
                                <div>{data?.us}</div>
                            </div>
                        </div>
                        </div>
                    ))
                   }

                    {version==="womenJeansGuide" &&
                    womenJeansClothing.map((data, index)=>(
                        <div key={index} onClick={()=>setSize(index)} style={{border:size===index?"2px solid black":"1px solid grey", padding:'20px 10px 10px', minWidth:'160px'}} >
                        <div>
                            <div className="size-guide-table-cell-circle" style={{border:size===index?"5px solid black":"1px solid black", margin:0}}></div>
                            <div> {data?.size} </div>
                        </div>
                        <div>is equivalent to a</div>
                        <div>UK {data?.uk} </div>
                        <div style={{width:"16px", borderTop:'1px solid black', height:'1px', margin:'10px 0'}}></div>
                        <div>
                            <div style={{display:'flex', justifyContent:'space-between'}}>
                                <div>EU</div>
                                <div>{data?.eu}</div>
                            </div>
                            <div style={{display:'flex', justifyContent:'space-between'}}>
                                <div>US</div>
                                <div>{data?.us}</div>
                            </div>
                        </div>
                        </div>
                    ))
                   }

                    {version==="menGuide" &&
                    menClothing.map((data, index)=>(
                        <div key={index} onClick={()=>setSize(index)} style={{border:size===index?"2px solid black":"1px solid grey", padding:'20px 10px 10px', minWidth:'160px'}} >
                        <div>
                            <div className="size-guide-table-cell-circle" style={{border:size===index?"5px solid black":"1px solid black", margin:0}}></div>
                            <div> {data?.size} </div>
                        </div>
                        <div>is equivalent to a</div>
                        <div>UK {data?.uk} </div>
                        <div style={{width:"16px", borderTop:'1px solid black', height:'1px', margin:'10px 0'}}></div>
                        <div>
                            <div style={{display:'flex', justifyContent:'space-between'}}>
                                <div>EU</div>
                                <div>{data?.eu}</div>
                            </div>
                            <div style={{display:'flex', justifyContent:'space-between'}}>
                                <div>US</div>
                                <div>{data?.us}</div>
                            </div>
                        </div>
                        </div>
                    ))
                   }
               </div>
                </div>
                </>}


                {/* <Divider style={{background:"#444444"}} /> */}

                {/* shoes size guide */}
                {productSubcategory === "footwear" &&
                <>
                <div>SHOE MEASUREMENTS</div>
                <div style={{fontSize:'12px', marginBottom:"8px"}}>Use the conversion chart below to find your size</div>
                <div style={{overflowX:'scroll'}}>
                <div style={{display:'flex', borderRight:'1px solid black', width:"fit-content"}}>
                    <div style={{width:!isTabletOrMobile?"300px":"120px"}}>
                        <div className='size-guide-table-title-cell cm-inch-container'>
                            <span>mm</span>
                            {/* <Switch onClick={()=>setMeasureInches(!measureInches)} />
                            <span>inch</span> */}
                        </div>
                        
                        <div className='size-guide-table-title-cell'>Footlength</div>
                    </div>
                   { (version==="womenGuide" || version==="womenJeansGuide") && 
                    womenShoes.map((data, index)=>(
                        <div className='size-guide-table-title-column' key={index} onClick={()=>setShoeSize(index)} style={{border:shoeSize===index?"1px solid black":"none"}} >
                        <div className='size-guide-table-title-cell' style={{textAlign:'center', height:"70px", borderTop:'1px solid black'}}>
                            <div className="size-guide-table-cell-circle" style={{border:shoeSize===index?"5px solid black":"1px solid black"}}></div>
                            <div> {data?.size} </div>
                        </div>
                        
                        <div className='size-guide-table-title-cell'> {data?.footlength} </div>
                        </div>
                    ))
                   }

                    {(version==="menGuide" || version==="menJeansGuide") && 
                    menShoes.map((data, index)=>(
                        <div className='size-guide-table-title-column' key={index} onClick={()=>setShoeSize(index)} style={{border:shoeSize===index?"1px solid black":"none"}} >
                        <div className='size-guide-table-title-cell' style={{textAlign:'center', height:"70px", borderTop:'1px solid black'}}>
                            <div className="size-guide-table-cell-circle" style={{border:shoeSize===index?"5px solid black":"1px solid black"}}></div>
                            <div> {data?.size} </div>
                        </div>
                        
                        <div className='size-guide-table-title-cell'> {data?.footlength} </div>
                        </div>
                    ))
                   }
               </div>
                </div>
                
                <Divider style={{background:"#444444"}} />

                
                <div>SIZE CONVERSION</div>
                <div style={{fontSize:'12px', marginBottom:"8px"}}>Use the conversion chart below to find your size</div>
                <div style={{overflowX:'scroll'}}>
                <div style={{display:'flex', width:"fit-content", gap:"8px"}}>
                   
                {(version==="womenGuide" || version==="womenJeansGuide") &&
                    womenShoes.map((data, index)=>(
                        <div key={index} onClick={()=>setShoeSize(index)} style={{border:shoeSize===index?"2px solid black":"1px solid grey", padding:'20px 10px 10px', minWidth:'160px'}} >
                        <div>
                            <div className="size-guide-table-cell-circle" style={{border:shoeSize===index?"5px solid black":"1px solid black", margin:0}}></div>
                            <div> {data?.size} </div>
                        </div>
                       
                        <div style={{width:"16px", borderTop:'1px solid black', height:'1px', margin:'10px 0'}}></div>
                        <div>
                            <div style={{display:'flex', justifyContent:'space-between'}}>
                                <div>US</div>
                                <div>{data?.us}</div>
                            </div>
                            <div style={{display:'flex', justifyContent:'space-between'}}>
                                <div>EU</div>
                                <div>{data?.eu}</div>
                            </div>
                        </div>
                        </div>
                    ))
                   }

                    {(version==="menGuide" || version==="menJeansGuide") &&
                    menShoes.map((data, index)=>(
                        <div key={index} onClick={()=>setShoeSize(index)} style={{border:shoeSize===index?"2px solid black":"1px solid grey", padding:'20px 10px 10px', minWidth:'160px'}} >
                        <div>
                            <div className="size-guide-table-cell-circle" style={{border:shoeSize===index?"5px solid black":"1px solid black", margin:0}}></div>
                            <div> {data?.size} </div>
                        </div>
                       
                        <div style={{width:"16px", borderTop:'1px solid black', height:'1px', margin:'10px 0'}}></div>
                        <div>
                            <div style={{display:'flex', justifyContent:'space-between'}}>
                                <div>US</div>
                                <div>{data?.us}</div>
                            </div>
                            <div style={{display:'flex', justifyContent:'space-between'}}>
                                <div>EU</div>
                                <div>{data?.eu}</div>
                            </div>
                        </div>
                        </div>
                    ))
                   }
               </div>
                </div>
                </>}

                {productSubcategory==="accessories" && <div>
                <div>ITEM MEASUREMENTS</div>
                <div style={{fontSize:'12px', marginBottom:"8px"}}>Use the conversion chart below to find your size</div>
                <div style={{overflowX:'scroll'}}>
                <div style={{display:'flex', borderRight:'1px solid black', width:"fit-content"}}>
                    <div style={{width:!isTabletOrMobile?"300px":"120px"}}>
                        <div className='size-guide-table-title-cell cm-inch-container'>
                            <span>cm</span>
                            <Switch 
                            disabled
                            // onClick={()=>setMeasureInches(!measureInches)} 
                            />
                            <span>inch</span>
                        </div>
                        
                        <div className='size-guide-table-title-cell'>Diameter</div>
                        <div className='size-guide-table-title-cell'>Circumference</div>
                    </div>
                   
                   { ringSizes.map((data, index)=>(
                        <div className='size-guide-table-title-column' key={index} onClick={()=>setSize(index)} style={{border:size===index?"1px solid black":"none"}} >
                        <div className='size-guide-table-title-cell' style={{textAlign:'center', height:"70px", borderTop:'1px solid black'}}>
                            <div className="size-guide-table-cell-circle" style={{border:size===index?"5px solid black":"1px solid black"}}></div>
                            <div> {data?.size} </div>
                        </div>
                        
                        <div className='size-guide-table-title-cell'> {data.diameter}  </div>
                        <div className='size-guide-table-title-cell'> {data.circumference}  </div>
                        </div>
                    ))}
                   
                   
               </div>
                </div>
                </div>}

                <Divider style={{background:"#444444"}}/>
                <div>CONTACT US</div>
                <div style={{marginBottom:'24px'}}>Still not sure what size to purchase? Email us at {' '}
                    <a href="mailto:customers@losode.com" style={{color:'#800000'}}>customers@losode.com</a>
                </div>

            </div>

            
      
        </Modal>

        <style jsx="true">
        {`
            .size-guide-modal{
                font-family:DomaineSansText-Light
            }
            .size-guide-product-section{
                display:flex;
                flex-direction:column;
                gap:8px
            }
            .cart-page-bag-section-body{
                display: flex;
                flex-direction: column;
                background: white;
              }
              .cart-page-bag-section-body-item{
                background: white;
                position: relative;
                // margin-bottom: 24px;
                // padding-bottom: 24px;
                // border-bottom: 1px solid #a7a7a7;
              }
              .cart-page-bag-section-body-item-main{
                display: flex;
                gap: 36px;
              }
              .size-guide-product-section-image{
                background-position: center;
                background-size: cover;
                background-repeat: no-repeat;
                height: 200px;
                width: 140px;
              }
              .cart-page-bag-section-body-item-delete-btn{
                position: absolute;
                top: 0;
                right: 0;
                font-size: 20px;
                cursor:pointer
              }
              .cart-page-bag-section-body-item-delete-btn:hover{
                color:#800000
              }

              .size-guide-table-title-cell{
                padding: 10px;
                border: 1px solid black;
                border-top: 0;
                border-right:0
              }
              .cm-inch-container{
                display: flex;
                gap: 8px;
                justify-content: center;
                align-items:center;
                border: 0;
                border-bottom: 1px solid black;
                height:70px
              }

              .size-guide-table-cell-circle{
                width: 24px;
                height: 24px;
                border: 1px solid black;
                border-radius: 50%;
                margin:auto;
              }
              .size-guide-table-title-column{
                width:150px;
                text-align:center;
              }
              .size-guide-modal ::-webkit-scrollbar {
                width: 20px;
              }
              .size-guide-modal ::-webkit-scrollbar-track {
                background-color: transparent;
              }
              .size-guide-modal ::-webkit-scrollbar-thumb {
                background-color: #d6dee1;
                border-radius: 20px;
                border: 6px solid transparent;
                background-clip: content-box;
              }
              .to-fit-table{
                border: 1px solid;
                width: 100%;
                text-align: center;
                margin-top:8px
              }
              .to-fit-table tr{

              }
              .to-fit-table th{
                width: 33%;
                border: 1px solid black;
                height: 60px;
                font-family: 'DomaineSansText-Regular';
                font-weight: bold;
              }
              .to-fit-table td{
                border: 1px solid black;
                height: 45px;
              }

              @media(max-width:640px){
                .size-guide-table-title-column{
                    width:120px
                }
              }
              
        `}
        </style>
    </>
    );
};

export default SizeGuideModalWeb;
