import { createRef } from "react";
import { Carousel } from "antd";
import { useMediaQuery } from "react-responsive";

const Quality = () => {
  const isTabletOrMobile = useMediaQuery({ maxWidth: 640 });

  const carouselSettings = {
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    // dots: false,
    infinite: false,
  };

  const carouselRef = createRef();

  const items = [
    {
      title: "Quick & Secure Deliveries",
      desc: "We have taken the utmost care in defining efficient logistics processes ",
      url: "/images/icon-truck.svg",
    },
    {
      title: "Fair Returns Policy",
      desc: "Our designers have fair policies in place to protect our customers",
      url: "/images/icon-returns.svg",
    },
    {
      title: "Easy to Use Platform",
      desc: "We have developed a platform that you can navigate with ease",
      url: "/images/icon-search.svg",
    },
    {
      title: "24/7 Support",
      desc: "Visit our contact us page for all the ways to reach us ",
      url: "/images/icon-support.svg",
    },
  ];

  return (
    <div className="quality-container">
      <h1 className="quality-title">Quality remains our utmost priority</h1>
      <h4 className="quality-desc">
        <span style={{fontFamily:"DomaineSansText-Regular"}}>‘What you see is definitely, what you get'.</span> <br/>
        We understand how important this is to our customers and our business. 
        We have put measures in place to ensure every purchase exceeds your expectations. 
        If not we will rectify the situation quickly and with ease. 
      </h4>
      
      <div className="quality-line-container">
        <span className="quality-line-span" />
      </div>

      {!isTabletOrMobile && (
        <div className="quality-items-container">
          {items.map((item, idx) => (
            <div className="quality-item-div" key={idx}>
              <img className="quality-icon" alt={item.title} src={item.url} />
              <h1 className="quality-item-title">{item.title}</h1>
              <h4 className="quality-item-desc">{item.desc}</h4>
            </div>
          ))}
        </div>
      )}

      {isTabletOrMobile && (
        <div style={{ marginTop: "40px", marginBottom: "80px" }}>
          <Carousel {...carouselSettings} ref={carouselRef} >
            {items.map((item, idx) => (
              <div>
                <div className="quality-item-div" key={idx}>
                  <img
                    className="quality-icon"
                    alt={item.title}
                    src={item.url}
                  />
                  <h1 className="quality-item-title">{item.title}</h1>
                  <h4 className="quality-item-desc">{item.desc}</h4>
                </div>
              </div>
            ))}
          </Carousel>
        </div>
      )}

      <style jsx="true">{`
        .quality-container {
          background: #F9F9F9;
          padding: 24px 100px;
          text-align: center;
        }
        .quality-title {
          font: normal 400 32px DomaineSansText-Medium;
          line-height: 60px;
          margin-bottom: 10px;
          text-transform: uppercase;
        }
        .quality-desc {
          font: normal 300 16px DomaineSansText-Light;
          line-height: 1.5;
          margin-bottom: 40px;
          max-width: 1000px;
          margin: 0 auto;
        }
        .quality-desc-bold {
          font: normal 400 15px DomaineSansText-Black;
        }
        .quality-items-container {
          display: flex;
          justify-content: space-between;
          margin-top: 24px;
          margin-bottom: 24px;
        }
        .quality-item-div {
          align-items: center;
          display: flex;
          flex-direction: column;
          width: 23%;
        }
        .quality-icon {
          width: 30px;
          height: 30px;
        }
        .quality-item-title {
          font: normal 700 16px DomaineSansText-Medium;
          line-height: 25px;
          text-align: center;
          margin-top: 8px;
          text-transform: capitalize;
        }
        .quality-item-desc {
          font: normal 300 14px DomaineSansText-Light;
          line-height: 1.5;
          text-align: center;
        }
        .quality-line-container {
        }

        .quality-line-span {
          display: block;
          border-bottom: 0.5px solid #dbdbdb;
          width: 100%;
          margin: 50px auto;
        }

        @media (max-width: 640px) {
          .quality-container {
            padding: 0 16px;
            margin: 40px 0;
            text-align: left;
          }
          .quality-title {
            font-size: 16px;
            line-height: normal;
          }
          .quality-desc {
            width: 100%;
            line-height: 22px;
            font-size: 14px;
          }
          .quality-desc-bold {
            font-size: 14px;
          }
          .quality-items-container {
            flex-direction: column;
            gap: 24px;
            margin-bottom: 60px;
            margin-top: 24px;
          }
          .quality-item-div {
            width: 100%;
          }
          .quality-item-title {
            font-family: "DomaineSansText-Regular";
            font-weight: 400;
            margin-bottom: 2px;
            font-size: 16px;
          }
          .quality-item-desc {
            font-size: 14px;
            width: 80%;
          }
          .quality-line-container {
          }
          .quality-line-span {
            margin: 24px auto !important;
          }
        }
      `}</style>
    </div>
  );
};

export default Quality;
