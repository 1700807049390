import { Button, Carousel } from "antd";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const Banner = () => {

  const { type } = useSelector((state) => state.user);

  const navigate = useNavigate();

  const shopNewIn = () => {
    if (type === "Men")
      navigate(`/new-in/men/cid=${1}&name=new-in-men-products`);
    if (type === "Women")
      navigate(`/new-in/women/cid=${2}&name=new-in-women-products`);
  };

  return (
    <div className="banner-container">
        {// checks that today is >= 2024-11-25 
        (new Date()>=new Date('2024-11-25')) ?
        <img src="https://res.cloudinary.com/losode-ng/image/upload/f_auto/v1732099890/website-pictures-optimized/bf-sale-homepage-banner-mobile_s7cbcj.png" alt="banner" className="bannercontainer-image"
        style={{height:'auto'}} 
        height={400} width={600}
        /> :
        <Carousel fade autoplay autoplaySpeed={5000} draggable speed={1200}>
          <img src="https://res.cloudinary.com/losode-ng/image/upload/w_650/f_auto/v1728530712/website-pictures-optimized/home-main-image-1_u8uyxj.png" alt="banner" className="bannercontainer-image" />
          <img src="https://res.cloudinary.com/losode-ng/image/upload/w_650/f_auto/v1728530746/website-pictures-optimized/home-main-image-2_t8r3tw.png" alt="banner" className="bannercontainer-image" />
          <img src="https://res.cloudinary.com/losode-ng/image/upload/w_650/f_auto/v1728530688/website-pictures-optimized/home-main-image-3_fvoy9k.png" alt="banner" className="bannercontainer-image" />
        </Carousel>}
      
      {(new Date()>=new Date('2024-11-25')) ?
      <div className="banner-content-container">
        <div className="banner-content-title">Our Black Friday <span style={{color:'#CF9E4E'}}>SALE</span> Is Now On</div>
        <div className="subtext">Our biggest deals so far are available to you! Get massive fashion and style savings from 100s' of amazing designers.</div>
        <Button type="link" onClick={() => navigate('/sale')} className="shop-now-btn">
          <span style={{textDecoration: 'underline'}}>Shop now</span>
        </Button>
      </div>
      :
      <div className="banner-content-container">
        <div className="banner-content-title">Unique Versatility from a Timeless Africa</div>
        <div className="subtext">From subtle and elegant pieces to bright colours and elaborate style. Do fashion with 100s’ of extravagant designers</div>
        <Button type="link" onClick={() => shopNewIn()} className="shop-now-btn">
          <span style={{textDecoration:'underline'}}>Shop now</span>
        </Button>
      </div>}

      <style jsx="true">{`
        .banner-container {
          position:relative;
          background-position: top;
        }
        .bannercontainer-image-container{
          display: flex;
          justify-content: space-between;
          width:100%;
          gap:0px;
        }
        .bannercontainer-image{
          flex: 1;
          height: 542px;
          width: 100%;
          object-fit: cover;
          object-position: top;
        }
        .banner-container .ant-carousel .slick-dots-bottom li button{
          bottom: 14px;
          height: 10px;
          border-radius: 130px;
          width: 10px;
          opacity: 0.5;
        }
        .banner-container .ant-carousel .slick-dots li.slick-active button{
          width: 20px;
          opacity: 1;
        }
        .banner-container .ant-carousel .slick-dots-bottom li button:before{
          display:none
        }
        .banner-content-container{
          margin: auto;
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          text-align: center;
          bottom: 0px;
          left: 0px;
          padding: 16px 16px 0;
        }
        .banner-content-container .banner-content-title{
          font-family: 'DomaineSansDisplay-SemiBold';
          font-size: 30px;
          text-align: center;
          line-height: 30px;
          margin: 8px auto;
        }
        .banner-content-container .subtext{
          font-size: 14px;
          font-family: DomaineSansText-Light;
          line-height: 18px;
        }
        
        .banner-content-container .shop-now-btn{
          color: rgb(0, 0, 0);
          font-family: 'DomaineSansText-Medium';
          padding: 0px;
          height: auto;
          font-size: 14px;
          margin-top: 8px;
          text-transform: capitalize;
        }



      `}</style>
    </div>
  );
};

export default Banner;
