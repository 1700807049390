import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const Ads = () => {
  const navigate = useNavigate();
  const { type } = useSelector((state) => state.user);

  const shopCategory = (cat, id) => {
    navigate(`/${type.toLowerCase()}/subcategory/${cat}/cid=${id}&page=1`);
  };

  return (
    <div className="ads-container">
      <div className="btn-container">
        <div>
          <h2 className="txt-ads-title">Hats for every occasion</h2>
          <h4 className="ads-desc">Timeless and Classic</h4>
          <p
            className="ads-btn"
            onClick={() => shopCategory("accessories", type === "Women" ? 10 : 9)}
          >
            Shop Accessories
          </p>
        </div>
      </div>
      <div className="ads-img" />

      <style jsx="true">
        {`
          .ads-container {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 108px;
            margin: 12px 0;
            padding: 0 16px;
          }
          .btn-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            flex: 1;
            background: #f9f9f9;
            height: 100%;
            padding: 0 10px;

          }
          .ads-desc {
            font: normal 400 12px DomaineSansText-Light;
            color: #525252;
          }
          .ads-btn {
            font: normal 400 11px DomaineSansText-Light;
            margin-top: 16px;
            margin-bottom: 0;
            color: #555555;
            cursor: pointer;
            text-decoration: underline;
          }
          .ads-img {
            flex-basis: 50%;
            background: url("/images/adbanner-mobile.png"); 
            background-position: top;
            background-repeat: no-repeat;
            background-size: cover;
            height: 100%;
          }
          .ads-container .txt-ads-title {
            font-family: "DomaineSansText-Regular";
            font-size: 14px;
            margin-bottom: 4px;
            line-height: 1;
          }

          
        `}
      </style>
    </div>
  );
};

export default Ads;
