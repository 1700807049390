import axios from "axios";

export const fetchBestSellers = async () => {
  try {
    const result = await axios.get("product/featured");
    return result.data;
  } catch (error) {
    return error.response.data;
  }
};
export const fetchAllProducts = async () => {
  try {
    const result = await axios.get("product/all/featured");
    return result.data;
  } catch (error) {
    return error.response.data;
  }
};
export const fetchNextProducts = async (url) => {
  try {
    const result = await axios.get(`${url}`);
    return result.data;
  } catch (error) {
    return error.response.data;
  }
};
export const fetchLastProducts = async (url) => {
  try {
    const result = await axios.get(`${url}`);
    return result.data;
  } catch (error) {
    return error.response.data;
  }
};
export const fetchGenderProducts = async (id, query, code) => {
  try {
    const url = query
      ? `product/shop/main/category/${id}${query}&currency=${code}`
      : `product/shop/main/category/${id}?currency=${code}`;
    const result = await axios.get(url);
    return result.data;
  } catch (error) {
    return error.response.data;
  }
};

export const fetchProductsByCategory = async (id, cat, query, code) => {
  try {
    const url = query
      ? `product/${cat}/${id}${query}&currency=${code}`
      : `product/${cat}/${id}?currency=${code}`;
    const result = await axios.get(url);
    return result.data;
  } catch (error) {
    return error.response.data;
  }
};
export const fetchProductsByBrands = async (slug, query, code) => {
  try {
    const url = query
      ? `product/brand/${slug}${query}&currency=${code}`
      : `product/brand/${slug}?currency=${code}`;
    const result = await axios.get(url);
    return result.data;
  } catch (error) {
    return error.response.data;
  }
};

export const fetchNewIn = async (id, query, code) => {
  try {
    const url = query
      ? `product/shop/new/in/${id}${query}&currency=${code}`
      : `product/shop/new/in/${id}?currency=${code}`;
    const result = await axios.get(url);
    return result.data;
  } catch (error) {
    return error.response.data;
  }
};

export const fetchOneProduct = async (slug, code) => {
  try {
    const result = await axios.get(`product/slug/${slug}?currency=${code}`);
    return result.data.data;
  } catch (error) {
    return error.response.data;
  }
};

export const fetchCategoryProducts = async (id, code) => {
  try {
    const result = await axios.get(
      `product/subcategory/${id}?currency=${code}`
    );
    return result.data;
  } catch (error) {
    return error.response.data;
  }
};

export const fetchHeaderCategories = async () => {
  try {
    const result = await axios.get(`categories`);
    return result.data;
  } catch (error) {
    return error.response.data;
  }
};

export const fetchFooterCategories = async () => {
  try {
    const result = await axios.get(`top/five/categories`);
    return result.data;
  } catch (error) {
    return error.response.data;
  }
};

export const searchProducts = async (value, code) => {
  try {
    const result = await axios.post(
      `search?keyword=${value}&currency=${code}`,
      {}
    );
    return result.data;
  } catch (error) {
    return error.response.data;
  }
};

export const fetchNewInMenu = async (id) => {
  try {
    const result = await axios.get(`new/in/menu/${id}`);
    return result.data;
  } catch (error) {
    return error.response.data;
  }
};
export const swapImage = async (data) => {
  try {
    const result = await axios.post(`seller/product/image/swap`, data);
    return result.data;
  } catch (error) {
    return error.response.data;
  }
};

export const deleteImage = async (data) => {
  try {
    const result = await axios.post(`seller/product/image/delete`, data);
    return result.data;
  } catch (error) {
    return error.response.data;
  }
};

export const filterNewIn = async (query) => {
  try {
    const result = await axios.get(`product/${query}`);
    // const result = await axios.get(
    //   `product/shop/new/in/${id}?sellerId=${sellerIds.join(
    //     ","
    //   )}&color=${colors.join(",")}&size=${sizes.join(",")}&fit=${fit.join(
    //     ","
    //   )}&occasion=${occasions.join(",")}&returns=${returns.join(
    //     ","
    //   )}&price=${parseFloat(price[0])},${parseFloat(
    //     price[1]
    //   )}&page=${page}&currency=${code}`
    // );
    return result.data;
  } catch (error) {
    return error.response.data;
  }
};

export const filterStore = async (slug, catIds, page, code) => {
  try {
    const result = await axios.get(
      `product/brand/${slug}?catIds=${catIds.join(
        ","
      )}&page=${page}&currency=${code}`
    );
    return result.data;
  } catch (error) {
    return error.response.data;
  }
};

export const filterCategory = async (subcatId, catIds, type, page, code) => {
  try {
    const url = subcatId
      ? `product/${type}/${subcatId}?page=${page}&catIds=${catIds.join(
          ","
        )}&currency=${code}`
      : `product/${type}?page=${page}&catIds=${catIds.join(
          ","
        )}&currency=${code}`;
    const result = await axios.get(url);
    return result.data;
  } catch (error) {
    return error.response.data;
  }
};

export const filterByOptions = async (query) => {
  try {
    const result = await axios.get(`product/${query}`);
    // const result = await axios.get(
    //   `product/${type}/${subcatId}?page=${page}&sellerId=${sellerIds.join(
    //     ","
    //   )}&color=${colors.join(",")}&size=${sizes.join(",")}&fit=${fit.join(
    //     ","
    //   )}&occasion=${occasions.join(",")}&returns=${returns.join(
    //     ","
    //   )}&price=${parseFloat(price[0])},${parseFloat(price[1])}&currency=${code}`
    // );
    return result.data;
  } catch (error) {
    return error.response.data;
  }
};

export const fetchHomepageProducts = async () => {
  try {
    const result = await axios.get(`landing/page?currency=NGN`);
    return result.data;
  } catch (error) {
    return error.response.data;
  }
};

export const fetchWomenHomepageProducts = async () => {
  try {
    const result = await axios.get(`landing/page/women`);
    return result.data;
  } catch (error) {
    return error.response.data;
  }
};

export const fetchMenHomepageProducts = async () => {
  try {
    const result = await axios.get(`landing/page/men`);
    return result.data;
  } catch (error) {
    return error.response.data;
  }
};

export const fetchSalepageProducts = async () => {
  try {
    const result = await axios.get(`sale/items?currency=NGN`);
    console.log("SALE API CALLL", result);
    return result.data;
  } catch (error) {
    return error.response.data;
  }
};

export const filterSalepageByOptions = async (query) => {
  try {
    const result = await axios.get(`sale/items${query ? `${query}&currency=NGN` : `?currency=NGN`}`);
    return result.data;
  } catch (error) {
    return error.response.data;
  }
};
