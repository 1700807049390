import { useMediaQuery } from "react-responsive";
import MensPageMobile from "./MensPageMobile";
import MensPageWeb from "./MensPageWeb";

const MensPage = () => {
  const isTabletOrMobile = useMediaQuery({ maxWidth: 480 });

  return <>{isTabletOrMobile ? <MensPageMobile /> : <MensPageWeb />}</>;
};

export default MensPage;
