// import Cookies from "./sections/Cookies";
import Ads from "./sections/Ads";
import Banner from "./sections/Banner";
import Bestseller from "./sections/Bestseller";
import NewInHomepage from "./sections/NewInHomepage";
import PopularCategory from "./sections/PopularCategory";
import Quality from "./sections/Quality";
import ShopGender from "./sections/ShopGender";
import DiscountModal from "./sections/DiscountModal";
import { useState } from "react";

const HomePageWeb = () => {

    const [showDiscountModal, setShowDiscountModal] = useState(false);

  return (
        <>
        <Banner />
        <NewInHomepage/>
        <ShopGender />
        <Bestseller />
        <Ads />
        <PopularCategory />
        <Quality />
        {/* <Cookies showDiscountModal={showDiscountModal} /> */}
          <DiscountModal
            showDiscountModal={showDiscountModal}
            setShowDiscountModal={setShowDiscountModal}
        />
        </>
  );
};

export default HomePageWeb;
