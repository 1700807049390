import React from "react";
import { useMediaQuery } from "react-responsive";
import QuantityModalMobile from "./QuantityModalMobile";
import QuantityModalWeb from "./QuantityModalWeb";

function QuantityModal({ product, visible, setVisible, type, itemSubCat, itemCat }) {
  const isTabletOrMobile = useMediaQuery({ maxWidth: 640 });
  return (
    <>
      {isTabletOrMobile ? (
        <QuantityModalMobile
          product={product}
          visible={visible}
          setVisible={setVisible}
          type={type}
          itemSubCat={itemSubCat}
          itemCat={itemCat}
        />
      ) : (
        <QuantityModalWeb
          product={product}
          visible={visible}
          setVisible={setVisible}
          type={type}
          itemSubCat={itemSubCat}
          itemCat={itemCat}
        />
      )}
    </>
  );
}

export default QuantityModal;
