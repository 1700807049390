import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const Ads = () => {
  const navigate = useNavigate();
  const { type } = useSelector((state) => state.user);

  const shopCategory = (cat, id) => {
    navigate(`/${type.toLowerCase()}/subcategory/${cat}/cid=${id}&page=1`);
  };

  return (
    <div className="ads-container">
      <div className="btn-container">
        <div>
          <h2 className="txt-ads-title">Hats For Every Occasion</h2>
          <h4 className="ads-desc">Timeless and Classic</h4>
          <p
            className="ads-btn"
            onClick={() => shopCategory("accessories", type === "Women" ? 10 : 9)}
          >
            Shop Accessories
          </p>
        </div>
      </div>
      <div className="ads-img" />

      <style jsx="true">
        {`
          .ads-container {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 15vw;
            margin: 24px 0;
            padding: 0 100px;
          }
          .btn-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            flex: 1;
            background: #f9f9f9;
            height: 100%;
            padding: 0 10px;
            align-items: center;

          }
          .ads-desc {
            font: normal 400 12px DomaineSansText-Regular;
            font-size: clamp(20px, 1.5vw, 45px);
            color: #525252;
          }
          .ads-btn {
            font: normal 400 11px DomaineSansText-Light;
            font-size: clamp(16px, 1vw, 45px);
            margin-top: 16px;
            margin-bottom: 0;
            color: #555555;
            cursor: pointer;
            text-decoration: underline;
          }
          .ads-img {
            flex-basis: 50%;
            background: url("https://res.cloudinary.com/losode-ng/image/upload/f_auto/v1732006399/website-pictures-optimized/ads-cap-bag-woman_pnlwah.png"); 
            background-position: top;
            background-repeat: no-repeat;
            background-size: cover;
            height: 100%;
          }
          .ads-container .txt-ads-title {
            font-family: "DomaineSansText-Medium";
            font-size: clamp(32px, 2vw, 100px);
            margin-bottom: 4px;
            line-height: 1;
          }

          
        `}
      </style>
    </div>
  );
};

export default Ads;
