import { Link } from "react-router-dom";
const DiscountHeader = ({ code = "NEW10" }) => {
  return (
    <div className="container">
      <Link className='discount-sell-link' to="/sell">
        Sell on Losode here
      </Link>
      <div className="vText">
        New to Losode? Use code <span className="code">{code}</span> for 10% off
        your first order
      </div>

      <style jsx="true">{`
        .container {
          align-items: center;
          background: #E1E1E1;
          display: flex;
          height: 40px;
          justify-content: space-between;
          padding: 0 24px;
        }
        .vText {
          font: normal 400 14px DomaineSansText-Regular;
          // margin-top: 15px;
        }
        .code {
          color: #000000;
          // font-weight: 700;
          font-style: normal;
          text-decoration: underline;
        }

        .discount-sell-link {
          text-decoration: underline;
          font: normal normal normal 14px DomaineSansText-Regular;
          letter-spacing: 0px;
          color: #000000;
        }

        @media(max-width:640px){
          .discount-sell-link{
            display: none;
          }
          .container{
            padding: 8px 16px;
            justify-content: center;
            height:auto;
            background: #f9f9f9;
          }
          .vText{
            font-size: 10px;
            text-align: center;
            margin: 0;
          }
          .code{
            color: #800000;
          }
        }
      `}</style>
    </div>
  );
};

export default DiscountHeader;
