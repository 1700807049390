import React from "react";

const Line = () => {
  return (
    <div className="line-container">
      <span className="line-span" />

      <style jsx="true">
        {`
          .line-container {
            padding: 0 100px;
          }

          .line-span {
            display: block;
            // border-bottom: 0.5px solid #ccc;
            width: 100%;
            margin: 40px auto 64px;
          }
          @media (max-width: 640px) {
            .line-container {
              padding: 0 16px;
            }
            .line-span {
              margin: 40px auto !important;
            }
          }
        `}
      </style>
    </div>
  );
};

export default Line;
