export const womenClothing =[
    { size:"x-small", bust: "78", waist:"60", hip:"83.5",uk:"4",eu:'32',us:"0" },
    { size:"small", bust: "80.5", waist:"62.5", hip:"86", uk:"6", eu:'34', us:"2" },
    { size:"medium-8", bust: "83", waist:"65", hip:"88.5", uk:"8", eu:'36', us:"4" },
    { size:"medium-10", bust: "88", waist:"70", hip:"93.5", uk:"10", eu:'38', us:"6" },
    { size:"medium-12", bust: "93", waist:"75", hip:"98.5", uk:"12", eu:'40', us:"8" },
    { size:"large", bust: "98", waist:"80", hip:"103.5", uk:"14", eu:'42', us:"10" },
    { size:"x-large", bust: "103", waist:"85", hip:"108.5", uk:"16", eu:'44', us:"12" },
    { size:"xxl", bust: "110.5", waist:"92.5", hip:"116", uk:"18", eu:'46', us:"14" }
]

export const womenJeansClothing =[
    { size:"x-small", bust: "73.5", waist:"55.5", hip:"80",uk:"2",eu:'30',us:"0" },
    { size:"small", bust: "76", waist:"58", hip:"82.5",uk:"4",eu:'32',us:"0" },
    { size:"medium-8", bust: "78.5", waist:"60.5", hip:"85", uk:"6", eu:'34', us:"2" },
    { size:"medium-10", bust: "81", waist:"63", hip:"68", uk:"8", eu:'36', us:"4" },
    { size:"medium-12", bust: "86", waist:"68", hip:"92.5", uk:"10", eu:'38', us:"6" },
    { size:"large", bust: "91", waist:"73", hip:"97.5", uk:"12", eu:'40', us:"8" },
    { size:"x-large", bust: "96", waist:"78", hip:"102.5", uk:"14", eu:'42', us:"10" },
    { size:"xxl", bust: "101", waist:"83", hip:"107.5", uk:"16", eu:'44', us:"12" },
]

export const womenShoes=[
    { size:"UK 2", footlength:"212", us:'4', eu:'35' },
    { size:"UK 3", footlength:"220", us:'5', eu:'36' },
    { size:"UK 4", footlength:"229", us:'6', eu:'37' },
    { size:"UK 5", footlength:"237", us:'7', eu:'38' },
    { size:"UK 6", footlength:"246", us:'8', eu:'39' },
    { size:"UK 7", footlength:"254", us:'9', eu:'40' },
    { size:"UK 8", footlength:"262", us:'10', eu:'41' },
    { size:"UK 9", footlength:"270", us:'11', eu:'42' }
]

export const menShoes=[
    { size:"UK 3", footlength:"220", us:'4', eu:'35.5' },
    { size:"UK 4", footlength:"229", us:'5', eu:'37' },
    { size:"UK 5", footlength:"237", us:'6', eu:'38' },
    { size:"UK 6", footlength:"246", us:'7', eu:'39' },
    { size:"UK 7", footlength:"254", us:'8', eu:'40.5' },
    { size:"UK 7.5", footlength:"258", us:'8.5', eu:'41' },
    { size:"UK 8", footlength:"262", us:'9', eu:'42' },
    { size:"UK 8.5", footlength:"266", us:'9.5', eu:'42.5' },
    { size:"UK 9", footlength:"271", us:'10', eu:'43' },
    { size:"UK 9.5", footlength:"275", us:'10.5', eu:'44' },
    { size:"UK 10", footlength:"279", us:'11', eu:'44.5' },
    { size:"UK 10.5", footlength:"283", us:'11.5', eu:'45' },
    { size:"UK 11", footlength:"288", us:'12', eu:'46' },
    { size:"UK 12", footlength:"296", us:'13', eu:'47' },
    { size:"UK 13", footlength:"305", us:'14', eu:'48' },
    { size:"UK 14", footlength:"314", us:'15', eu:'49.5' }
]


export const menClothing =[
    { size:"xs", chest: "86 - 91", chestInches: "34 - 36", neck:"38.5", neckInches: "14 - 14.5", eu:'36',us:"14.5", uk:"x-small" },
    { size:"small", chest: "91 - 96", chestInches: "36 - 38", neck:"39.5", neckInches: "15 - 15.5", eu:'38', us:"15" },
    { size:"medium", chest: "96 - 101", chestInches: "38 - 40", neck:"41.5", neckInches: "16 - 16.5", eu:'40', us:"15.5" },
    { size:"large", chest: "101 -106", chestInches: "40 - 42", neck:"43.5", neckInches: "17 - 17.5", eu:'42', us:"16" },
    { size:"x-large", chest: "106 - 111", chestInches: "42 - 44", neck:"45.5", neckInches: "18 - 18.5", eu:'44', us:"16.5" },
    { size:"xxl", chest: "111 - 116", chestInches: "44 - 46", neck:"47.5", neckInches: "19 - 19.5", eu:'46', us:"17" },
    { size:"3xL", chest: "116 - 121", chestInches: "46 - 48", neck:"49.5", neckInches: "20 - 20.5", eu:'48', us:"17.5" },
]

export const menFitWaistNew = [
{uk: "xx-small", usIn: "24", usCm: "59 - 64", eu: "34 - 36", lengthCm: "81", lengthIn: "32" },
{uk: "xx-small", usIn: "26", usCm: "64 - 69", eu: "34 - 36", lengthCm: "81", lengthIn: "32" },
{uk: "x-small", usIn: "28", usCm: "69 - 74", eu: "38", lengthCm: "81", lengthIn: "32" },
{uk: "small", usIn: "30", usCm: "74 - 79", eu: "40 - 42", lengthCm: "81", lengthIn: "32" },
{uk: "small", usIn: "32", usCm: "79 - 84", eu: "40 - 42", lengthCm: "81", lengthIn: "32" },
{uk: "medium", usIn: "34", usCm: "84 - 89", eu: "44", lengthCm: "81", lengthIn: "32" },
{uk: "large", usIn: "36", usCm: "89 - 94", eu: "46 - 48", lengthCm: "81", lengthIn: "32" },
{uk: "large", usIn: "38", usCm: "94 - 97", eu: "46 - 48", lengthCm: "81", lengthIn: "32" },
{uk: "x-large", usIn: "40", usCm: "99 - 104", eu: "50", lengthCm: "81", lengthIn: "32" },
{uk: "xx-large", usIn: "42", usCm: "104 - 109", eu: "52 -54", lengthCm: "81", lengthIn: "32" },
{uk: "xx-large", usIn: "44", usCm: "109 - 114", eu: "52 -54", lengthCm: "81", lengthIn: "32" },
{uk: "3xl", usIn: "46", usCm: "114 - 119", eu: "56", lengthCm: "81", lengthIn: "32" },
{uk: "4xl", usIn: "48", usCm: "119 - 124", eu: "58", lengthCm: "81", lengthIn: "32" },
]

export const menKaftan = [
    {
      size: "S",
      neck: "15",
      shoulder: "17",
      sleeveLength: "24",
      chest: "41",
      stomach: "40",
      bodyLength: "34",
      waist: "33",
      lap: "25",
      trouserLength: "40"
    },
    {
      size: "M",
      neck: "16",
      shoulder: "19",
      sleeveLength: "25.5",
      chest: "44",
      stomach: "43",
      bodyLength: "35",
      waist: "37",
      lap: "26.5",
      trouserLength: "40"
    },
    {
      size: "L",
      neck: "17",
      shoulder: "20",
      sleeveLength: "26",
      chest: "46",
      stomach: "45",
      bodyLength: "35",
      waist: "40",
      lap: "30",
      trouserLength: "42"
    },
    {
      size: "XL",
      neck: "18",
      shoulder: "21",
      sleeveLength: "26",
      chest: "50",
      stomach: "49",
      bodyLength: "35",
      waist: "45",
      lap: "33",
      trouserLength: "42"
    },
    {
      size: "XXL",
      neck: "21",
      shoulder: "23",
      sleeveLength: "27",
      chest: "58",
      stomach: "57",
      bodyLength: "38",
      waist: "49",
      lap: "37",
      trouserLength: "42"
    },
    {
      size: "MT (Medium Tall)",
      neck: "16",
      shoulder: "19",
      sleeveLength: "29",
      chest: "44",
      stomach: "43",
      bodyLength: "40",
      waist: "38",
      lap: "28",
      trouserLength: "43"
    }
  ]
  

export const ringSizes =[
    { size:"xs", diameter: "0.17", circumference:"0.54", diameterInches: "0.67", circumferenceInches: "2.12" },
    { size:"s", diameter: "0.18", circumference:"0.57", diameterInches: "0.72", circumferenceInches: "2.25" },
    { size:"m", diameter: "0.19", circumference:"0.6", diameterInches: "0.75", circumferenceInches: "2.36" },
    { size:"l", diameter: "0.2", circumference:"0.63", diameterInches: "0.79", circumferenceInches: "2.48" },
    { size:"xl", diameter: "0.21", circumference:"0.66", diameterInches: "0.82", circumferenceInches: "2.59" },
    { size:"xxl", diameter: "0.22", circumference:"0.69", diameterInches: "0.87", circumferenceInches: "2.73" },
]

export const womenRingSizes = [
    { uk: "xs", eu: "16", us: "16" },
    { uk: "s", eu: "17", us: "17" },
    { uk: "m", eu: "18", us: "18" },
    { uk: "l", eu: "19", us: "19" },
    { uk: "xl", eu: "20", us: "20" },
]