import React from "react";
import { useMediaQuery } from "react-responsive";
import SizeGuideModalMobile from "./SizeGuideModalMobile";
import SizeGuideModalWeb from "./SizeGuideModalWeb";

function SizeGuideModal({
  sizeGuideModalVisible,
  setSizeGuideModalVisible,
  version,
  designerName,
  productName,
  productImage,
  productSubcategory,
  productSizeAndFit,
  subChildCat,
  productDescription
}) {
  const isTabletOrMobile = useMediaQuery({ maxWidth: 640 });

  return (
    <>
      {isTabletOrMobile ? (
        <SizeGuideModalMobile
          sizeGuideModalVisible={sizeGuideModalVisible}
          setSizeGuideModalVisible={setSizeGuideModalVisible}
          version={version}
          designerName={designerName}
          productName={productName}
          productImage={productImage}
          productSubcategory={productSubcategory}
          productSizeAndFit={productSizeAndFit}
          subChildCat={subChildCat}
          productDescription={productDescription}
        />
      ) : (
        <SizeGuideModalWeb
          sizeGuideModalVisible={sizeGuideModalVisible}
          setSizeGuideModalVisible={setSizeGuideModalVisible}
          version={version}
          designerName={designerName}
          productName={productName}
          productImage={productImage}
          productSubcategory={productSubcategory}
        />
      )}
    </>
  );
}

export default SizeGuideModal;
