import { Divider, Modal, Switch } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { useState } from "react";
import {
  womenClothing,
  womenJeansClothing,
  womenShoes,
  menShoes,
  menClothing,
  menFitWaistNew,
  ringSizes,
  womenRingSizes,
  menKaftan,
} from "./size-guide-data";

const SizeGuideModalMobile = ({
  sizeGuideModalVisible,
  setSizeGuideModalVisible,
  version,
  designerName,
  productName,
  productImage,
  productSubcategory,
  productSizeAndFit,
  subChildCat,
  productDescription,
}) => {
  const [size, setSize] = useState(0);
  const [shoeSize, setShoeSize] = useState(0);
  const [measureInches, setMeasureInches] = useState(false);

  const properties = [
    "neck",
    "shoulder",
    "sleeveLength",
    "chest",
    "stomach",
    "bodyLength",
    "waist",
    "lap",
    "trouserLength",
  ];

  return (
    <>
      <Modal
        centered
        open={sizeGuideModalVisible}
        footer={null}
        width="100%"
        onCancel={() => {
          setSizeGuideModalVisible(false);
        }}
        closeIcon={
          <CloseOutlined
            style={{
              color: "#000",
              //   padding: "15px 10px 0 0",
              fontSize: "18px",
            }}
          />
        }
        transitionName=""
        style={{ opacity: "100%", padding: "0" }}
        // maskClosable={false}
        className="size-guide-modal"
      >
        <div>
          {version === "womenGuide" &&
            subChildCat?.toLowerCase() !== "trousers" && (
              <div>
                WOMEN'S SIZE GUIDE{" "}
                {`(${
                  productSubcategory === "footwear"
                    ? "SHOES"
                    : productSubcategory === "accessories"
                    ? "ACCESSORIES"
                    : "CLOTHINGS"
                })`}
              </div>
            )}
          {version === "womenGuide" &&
            subChildCat?.toLowerCase() === "trousers" && (
              <div>WOMEN'S SIZE GUIDE (JEANS/TROUSER AND SHOES)</div>
            )}
          {version === "menGuide" &&
            subChildCat?.toLowerCase() === "trousers" && (
              <div>MEN'S SIZE GUIDE (JEANS/TROUSER)</div>
            )}

          {version === "menGuide" &&
            subChildCat?.toLowerCase() !== "trousers" && (
              <div>
                MEN'S SIZE GUIDE{" "}
                {productSubcategory === "footwear" ? "(SHOES)" : "(CLOTHINGS)"}{" "}
              </div>
            )}

          <Divider style={{ background: "#444444", margin: "4px 0 8px" }} />

          <>
            <div className="cart-page-bag-section-body">
              <div className="cart-page-bag-section-body-item">
                <div className="cart-page-bag-section-body-item-main">
                  <div
                    className="size-guide-product-section-image"
                    style={{
                      backgroundImage: `url(${productImage?.imageURL})`,
                    }}
                  ></div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      flex: 1,
                    }}
                  >
                    <div className="size-guide-product-section">
                      <div
                        style={{
                          fontWeight: "600",
                          fontFamily: "DomaineSansText-Regular",
                        }}
                      >
                        {designerName}
                      </div>
                      <div style={{ fontSize: "12px" }}> {productName} </div>
                      <div
                        style={{
                          fontSize: "12px",
                          wordWrap: "break-word",
                          lineHeight: "1.2",
                        }}
                      >
                        {productDescription}
                      </div>

                      {productSizeAndFit && (
                        <ul
                          style={{
                            fontSize: "12px",
                            paddingInlineStart: "16px",
                            display: "flex",
                            flexDirection: "column",
                            gap: "4px",
                          }}
                        >
                          <li>{productSizeAndFit}</li>
                        </ul>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {productSubcategory !== "footwear" && (
              <Divider
                style={{ background: "#444444", margin: "4px 0 18px" }}
              />
            )}
            {version === "womenGuide" &&
              subChildCat?.toLowerCase() !== "trousers" &&
              productSubcategory !== "footwear" &&
              productSubcategory !== "accessories" && (
                <div>
                  <div>CLOTHING MEASUREMENTS</div>
                  <div style={{ fontSize: "12px", marginBottom: "8px" }}>
                    Use the conversion chart below to find your size
                  </div>
                  <div style={{ overflowX: "scroll" }}>
                    <div
                      style={{
                        display: "flex",
                        borderRight: "1px solid black",
                        width: "fit-content",
                      }}
                    >
                      <div
                        style={{
                          width: "120px",
                        }}
                      >
                        <div className="size-guide-table-title-cell cm-inch-container">
                          <span>cm</span>
                          <Switch
                            onClick={() => setMeasureInches(!measureInches)}
                          />
                          <span>inch</span>
                        </div>

                        <div className="size-guide-table-title-cell">Bust</div>
                        <div className="size-guide-table-title-cell">Waist</div>
                        <div className="size-guide-table-title-cell">Hip</div>
                      </div>

                      {womenClothing.map((data, index) => (
                        <div
                          className="size-guide-table-title-column"
                          key={index}
                          onClick={() => setSize(index)}
                          style={{
                            border: size === index ? "1px solid black" : "none",
                          }}
                        >
                          <div
                            className="size-guide-table-title-cell"
                            style={{
                              textAlign: "center",
                              height: "70px",
                              borderTop: "1px solid black",
                            }}
                          >
                            <div
                              className="size-guide-table-cell-circle"
                              style={{
                                border:
                                  size === index
                                    ? "5px solid black"
                                    : "1px solid black",
                              }}
                            ></div>
                            <div> {data?.size} </div>
                          </div>

                          <div className="size-guide-table-title-cell">
                            {" "}
                            {!measureInches
                              ? data?.bust
                              : Math.round(data?.bust * 0.3937)}{" "}
                          </div>
                          <div className="size-guide-table-title-cell">
                            {" "}
                            {!measureInches
                              ? data?.waist
                              : Math.round(data?.waist * 0.3937)}{" "}
                          </div>
                          <div className="size-guide-table-title-cell">
                            {" "}
                            {!measureInches
                              ? data?.hip
                              : Math.round(data?.hip * 0.3937)}{" "}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              )}

            {version === "womenGuide" &&
              subChildCat?.toLowerCase() === "trousers" &&
              productSubcategory !== "footwear" && (
                <div>
                  <div>CLOTHING MEASUREMENTS</div>
                  <div style={{ fontSize: "12px", marginBottom: "8px" }}>
                    Use the conversion chart below to find your size
                  </div>
                  <div style={{ overflowX: "scroll" }}>
                    <div
                      style={{
                        display: "flex",
                        borderRight: "1px solid black",
                        width: "fit-content",
                      }}
                    >
                      <div
                        style={{
                          width: "120px",
                        }}
                      >
                        <div className="size-guide-table-title-cell cm-inch-container">
                          <span>cm</span>
                          <Switch
                            onClick={() => setMeasureInches(!measureInches)}
                          />
                          <span>inch</span>
                        </div>

                        <div className="size-guide-table-title-cell">Bust</div>
                        <div className="size-guide-table-title-cell">Waist</div>
                        <div className="size-guide-table-title-cell">Hip</div>
                      </div>

                      {womenJeansClothing.map((data, index) => (
                        <div
                          className="size-guide-table-title-column"
                          key={index}
                          onClick={() => setSize(index)}
                          style={{
                            border: size === index ? "1px solid black" : "none",
                          }}
                        >
                          <div
                            className="size-guide-table-title-cell"
                            style={{
                              textAlign: "center",
                              height: "70px",
                              borderTop: "1px solid black",
                            }}
                          >
                            <div
                              className="size-guide-table-cell-circle"
                              style={{
                                border:
                                  size === index
                                    ? "5px solid black"
                                    : "1px solid black",
                              }}
                            ></div>
                            <div> {data?.size} </div>
                          </div>

                          <div className="size-guide-table-title-cell">
                            {" "}
                            {!measureInches
                              ? data?.bust
                              : Math.round(data?.bust * 0.3937)}{" "}
                          </div>
                          <div className="size-guide-table-title-cell">
                            {" "}
                            {!measureInches
                              ? data?.waist
                              : Math.round(data?.waist * 0.3937)}{" "}
                          </div>
                          <div className="size-guide-table-title-cell">
                            {" "}
                            {!measureInches
                              ? data?.hip
                              : Math.round(data?.hip * 0.3937)}{" "}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              )}

            {version === "menGuide" &&
              productSubcategory !== "footwear" &&
              productSubcategory !== "accessories" &&
              subChildCat?.toLowerCase() !== "kaftans" && (
                <div>
                  <div>CLOTHING MEASUREMENTS</div>
                  <div style={{ fontSize: "12px", marginBottom: "8px" }}>
                    Use the conversion chart below to find your size
                  </div>
                  <div style={{ overflowX: "scroll" }}>
                    <div
                      style={{
                        display: "flex",
                        borderRight: "1px solid black",
                        width: "fit-content",
                      }}
                    >
                      <div
                        style={{
                          width: "120px",
                        }}
                      >
                        <div className="size-guide-table-title-cell cm-inch-container">
                          <span>cm</span>
                          <Switch
                            onClick={() => setMeasureInches(!measureInches)}
                          />
                          <span>inch</span>
                        </div>

                        <div className="size-guide-table-title-cell">Chest</div>
                        <div className="size-guide-table-title-cell">Neck</div>
                      </div>

                      {menClothing.map((data, index) => (
                        <div
                          className="size-guide-table-title-column"
                          key={index}
                          onClick={() => setSize(index)}
                          style={{
                            border: size === index ? "1px solid black" : "none",
                          }}
                        >
                          <div
                            className="size-guide-table-title-cell"
                            style={{
                              textAlign: "center",
                              height: "70px",
                              borderTop: "1px solid black",
                            }}
                          >
                            <div
                              className="size-guide-table-cell-circle"
                              style={{
                                border:
                                  size === index
                                    ? "5px solid black"
                                    : "1px solid black",
                              }}
                            ></div>
                            <div> {data?.size} </div>
                          </div>

                          <div className="size-guide-table-title-cell">
                            {" "}
                            {!measureInches ? data?.chest : data?.chestInches}
                          </div>
                          <div className="size-guide-table-title-cell">
                            {" "}
                            {!measureInches ? data?.neck : data?.neckInches}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              )}

            {version === "menGuide" &&
              productSubcategory !== "footwear" &&
              productSubcategory !== "accessories" &&
              subChildCat?.toLowerCase() === "kaftans" && (
                <div>
                  <div>CLOTHING MEASUREMENTS</div>
                  <div style={{ fontSize: "12px", marginBottom: "8px" }}>
                    Use the conversion chart below to find your size
                  </div>
                  <div style={{ overflowX: "scroll" }}>
                    <table border="1" cellPadding="10" className="kaftan-table">
                      <thead>
                        <tr>
                          <th>Size</th>
                          {menKaftan.map((item, index) => (
                            <th key={index}>{item.size}</th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {properties.map((property, propIndex) => (
                          <tr key={propIndex}>
                            <td style={{}}>
                              {property.charAt(0).toUpperCase() +
                                property.slice(1)}
                            </td>
                            {menKaftan.map((item, sizeIndex) => (
                              <td
                                key={sizeIndex}
                                style={{ textAlign: "center" }}
                              >
                                {item[property]}
                              </td>
                            ))}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              )}

            {version === "menGuide" &&
              subChildCat?.toLowerCase() === "trousers" &&
              productSubcategory !== "footwear" && (
                <div>
                  <div>CLOTHING MEASUREMENTS</div>
                  <div style={{ fontSize: "12px", marginBottom: "8px" }}>
                    Use the conversion chart below to find your size
                  </div>
                  <div style={{ overflowX: "scroll" }}>
                    <div
                      style={{
                        display: "flex",
                        borderRight: "1px solid black",
                        width: "fit-content",
                      }}
                    >
                      <div
                        style={{
                          width: "120px",
                        }}
                      >
                        <div className="size-guide-table-title-cell cm-inch-container">
                          <span>cm</span>
                          <Switch
                            onClick={() => setMeasureInches(!measureInches)}
                          />
                          <span>inch</span>
                        </div>

                        <div className="size-guide-table-title-cell">US</div>
                        <div className="size-guide-table-title-cell">EU</div>
                        <div className="size-guide-table-title-cell">
                          Length
                        </div>
                      </div>

                      {menFitWaistNew.map((data, index) => (
                        <div
                          className="size-guide-table-title-column"
                          key={index}
                          onClick={() => setSize(index)}
                          style={{
                            border: size === index ? "1px solid black" : "none",
                          }}
                        >
                          <div
                            className="size-guide-table-title-cell"
                            style={{
                              textAlign: "center",
                              height: "70px",
                              borderTop: "1px solid black",
                            }}
                          >
                            <div
                              className="size-guide-table-cell-circle"
                              style={{
                                border:
                                  size === index
                                    ? "5px solid black"
                                    : "1px solid black",
                              }}
                            ></div>
                            <div> {data?.uk} </div>
                          </div>

                          <div className="size-guide-table-title-cell">
                            {!measureInches ? data?.usCm : data?.usIn}
                          </div>
                          <div className="size-guide-table-title-cell">
                            {data?.eu}
                          </div>
                          <div className="size-guide-table-title-cell">
                            {!measureInches ? data?.lengthCm : data?.lengthIn}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              )}

            {productSubcategory !== "accessories" &&
              subChildCat?.toLowerCase() !== "kaftans" && (
                <Divider style={{ background: "#444444" }} />
              )}

            {productSubcategory !== "footwear" &&
              productSubcategory !== "accessories" &&
              subChildCat?.toLowerCase() !== "kaftans" && (
                <>
                  <div>SIZE CONVERSION</div>
                  <div style={{ fontSize: "12px", marginBottom: "8px" }}>
                    Use the conversion chart below to find your size
                  </div>
                  <div style={{ overflowX: "scroll" }}>
                    <div
                      style={{
                        display: "flex",
                        width: "fit-content",
                        gap: "8px",
                      }}
                    >
                      {version === "womenGuide" &&
                        subChildCat?.toLowerCase() !== "trousers" &&
                        womenClothing.map((data, index) => (
                          <div
                            key={index}
                            onClick={() => setSize(index)}
                            style={{
                              border:
                                size === index
                                  ? "2px solid black"
                                  : "1px solid grey",
                              padding: "20px 10px 10px",
                              minWidth: "160px",
                            }}
                          >
                            <div>
                              <div
                                className="size-guide-table-cell-circle"
                                style={{
                                  border:
                                    size === index
                                      ? "5px solid black"
                                      : "1px solid black",
                                  margin: 0,
                                }}
                              ></div>
                              <div> {data?.size} </div>
                            </div>
                            <div>is equivalent to a</div>
                            <div>UK {data?.uk} </div>
                            <div
                              style={{
                                width: "16px",
                                borderTop: "1px solid black",
                                height: "1px",
                                margin: "10px 0",
                              }}
                            ></div>
                            <div>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <div>EU</div>
                                <div>{data?.eu}</div>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <div>US</div>
                                <div>{data?.us}</div>
                              </div>
                            </div>
                          </div>
                        ))}

                      {version === "womenGuide" &&
                        subChildCat?.toLowerCase() === "trousers" &&
                        womenJeansClothing.map((data, index) => (
                          <div
                            key={index}
                            onClick={() => setSize(index)}
                            style={{
                              border:
                                size === index
                                  ? "2px solid black"
                                  : "1px solid grey",
                              padding: "20px 10px 10px",
                              minWidth: "160px",
                            }}
                          >
                            <div>
                              <div
                                className="size-guide-table-cell-circle"
                                style={{
                                  border:
                                    size === index
                                      ? "5px solid black"
                                      : "1px solid black",
                                  margin: 0,
                                }}
                              ></div>
                              <div> {data?.size} </div>
                            </div>
                            <div>is equivalent to a</div>
                            <div>UK {data?.uk} </div>
                            <div
                              style={{
                                width: "16px",
                                borderTop: "1px solid black",
                                height: "1px",
                                margin: "10px 0",
                              }}
                            ></div>
                            <div>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <div>EU</div>
                                <div>{data?.eu}</div>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <div>US</div>
                                <div>{data?.us}</div>
                              </div>
                            </div>
                          </div>
                        ))}

                      {version === "menGuide" &&
                        subChildCat?.toLowerCase() !== "trousers" &&
                        menClothing.map((data, index) => (
                          <div
                            key={index}
                            onClick={() => setSize(index)}
                            style={{
                              border:
                                size === index
                                  ? "2px solid black"
                                  : "1px solid grey",
                              padding: "20px 10px 10px",
                              minWidth: "160px",
                            }}
                          >
                            <div>
                              <div
                                className="size-guide-table-cell-circle"
                                style={{
                                  border:
                                    size === index
                                      ? "5px solid black"
                                      : "1px solid black",
                                  margin: "0 0 8px",
                                }}
                              ></div>
                            </div>
                            <div>{data?.uk ? data.uk : data?.size} </div>
                            <div
                              style={{
                                width: "16px",
                                borderTop: "1px solid black",
                                height: "1px",
                                margin: "10px 0",
                              }}
                            ></div>
                            <div>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <div>EU</div>
                                <div>{data?.eu}</div>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <div>US</div>
                                <div>{data?.us}</div>
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                </>
              )}
          </>

          {/* <Divider style={{background:"#444444"}} /> */}

          {/* shoes size guide */}
          {productSubcategory === "footwear" && (
            <>
              <div>SHOE MEASUREMENTS</div>
              <div style={{ fontSize: "12px", marginBottom: "8px" }}>
                Use the conversion chart below to find your size
              </div>
              <div style={{ overflowX: "scroll" }}>
                <div
                  style={{
                    display: "flex",
                    borderRight: "1px solid black",
                    width: "fit-content",
                  }}
                >
                  <div style={{ width: "120px" }}>
                    <div className="size-guide-table-title-cell cm-inch-container">
                      <span>mm</span>
                      {/* <Switch onClick={()=>setMeasureInches(!measureInches)} />
                            <span>inch</span> */}
                    </div>

                    <div className="size-guide-table-title-cell">
                      Footlength
                    </div>
                  </div>
                  {version === "womenGuide" &&
                    womenShoes.map((data, index) => (
                      <div
                        className="size-guide-table-title-column"
                        key={index}
                        onClick={() => setShoeSize(index)}
                        style={{
                          border:
                            shoeSize === index ? "1px solid black" : "none",
                        }}
                      >
                        <div
                          className="size-guide-table-title-cell"
                          style={{
                            textAlign: "center",
                            height: "70px",
                            borderTop: "1px solid black",
                          }}
                        >
                          <div
                            className="size-guide-table-cell-circle"
                            style={{
                              border:
                                shoeSize === index
                                  ? "5px solid black"
                                  : "1px solid black",
                            }}
                          ></div>
                          <div> {data?.size} </div>
                        </div>

                        <div className="size-guide-table-title-cell">
                          {" "}
                          {data?.footlength}{" "}
                        </div>
                      </div>
                    ))}

                  {version === "menGuide" &&
                    menShoes.map((data, index) => (
                      <div
                        className="size-guide-table-title-column"
                        key={index}
                        onClick={() => setShoeSize(index)}
                        style={{
                          border:
                            shoeSize === index ? "1px solid black" : "none",
                        }}
                      >
                        <div
                          className="size-guide-table-title-cell"
                          style={{
                            textAlign: "center",
                            height: "70px",
                            borderTop: "1px solid black",
                          }}
                        >
                          <div
                            className="size-guide-table-cell-circle"
                            style={{
                              border:
                                shoeSize === index
                                  ? "5px solid black"
                                  : "1px solid black",
                            }}
                          ></div>
                          <div> {data?.size} </div>
                        </div>

                        <div className="size-guide-table-title-cell">
                          {data?.footlength}
                        </div>
                      </div>
                    ))}
                </div>
              </div>

              <Divider style={{ background: "#444444" }} />

              <div>SIZE CONVERSION</div>
              <div style={{ fontSize: "12px", marginBottom: "8px" }}>
                Use the conversion chart below to find your size
              </div>
              <div style={{ overflowX: "scroll" }}>
                <div
                  style={{ display: "flex", width: "fit-content", gap: "8px" }}
                >
                  {version === "womenGuide" &&
                    womenShoes.map((data, index) => (
                      <div
                        key={index}
                        onClick={() => setShoeSize(index)}
                        style={{
                          border:
                            shoeSize === index
                              ? "2px solid black"
                              : "1px solid grey",
                          padding: "20px 10px 10px",
                          minWidth: "160px",
                        }}
                      >
                        <div>
                          <div
                            className="size-guide-table-cell-circle"
                            style={{
                              border:
                                shoeSize === index
                                  ? "5px solid black"
                                  : "1px solid black",
                              margin: 0,
                            }}
                          ></div>
                          <div> {data?.size} </div>
                        </div>

                        <div
                          style={{
                            width: "16px",
                            borderTop: "1px solid black",
                            height: "1px",
                            margin: "10px 0",
                          }}
                        ></div>
                        <div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <div>US</div>
                            <div>{data?.us}</div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <div>EU</div>
                            <div>{data?.eu}</div>
                          </div>
                        </div>
                      </div>
                    ))}

                  {version === "menGuide" &&
                    menShoes.map((data, index) => (
                      <div
                        key={index}
                        onClick={() => setShoeSize(index)}
                        style={{
                          border:
                            shoeSize === index
                              ? "2px solid black"
                              : "1px solid grey",
                          padding: "20px 10px 10px",
                          minWidth: "160px",
                        }}
                      >
                        <div>
                          <div
                            className="size-guide-table-cell-circle"
                            style={{
                              border:
                                shoeSize === index
                                  ? "5px solid black"
                                  : "1px solid black",
                              margin: 0,
                            }}
                          ></div>
                          <div> {data?.size} </div>
                        </div>

                        <div
                          style={{
                            width: "16px",
                            borderTop: "1px solid black",
                            height: "1px",
                            margin: "10px 0",
                          }}
                        ></div>
                        <div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <div>US</div>
                            <div>{data?.us}</div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <div>EU</div>
                            <div>{data?.eu}</div>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </>
          )}

          {productSubcategory === "accessories" && version === "womenGuide" && (
            <div>
              <div>ITEM MEASUREMENTS</div>
              <div style={{ fontSize: "12px", marginBottom: "8px" }}>
                Use the conversion chart below to find your size
              </div>

              <table className="ring-size-table">
                <thead>
                  <tr>
                    <th>UK Size</th>
                    <th>US Size (MM)</th>
                    <th>EU Size (MM)</th>
                  </tr>
                </thead>
                <tbody>
                  {womenRingSizes.map((data, index) => (
                    <tr key={index}>
                      <td>{data.uk}</td>
                      <td>{data.us}</td>
                      <td>{data.eu}</td>
                    </tr>
                  ))}
                </tbody>
              </table>

              <div>
                <div
                  style={{
                    display: "",
                    borderRight: "1px solid black",
                    width: "100%",
                  }}
                ></div>
              </div>
            </div>
          )}
          {productSubcategory === "accessories" && version === "menGuide" && (
            <div>
              <div>ITEM MEASUREMENTS</div>
              <div style={{ fontSize: "12px", marginBottom: "8px" }}>
                Use the conversion chart below to find your size
              </div>
              <div style={{ overflowX: "scroll" }}>
                <div
                  style={{
                    display: "flex",
                    borderRight: "1px solid black",
                    width: "fit-content",
                  }}
                >
                  <div style={{ width: "120px" }}>
                    <div className="size-guide-table-title-cell cm-inch-container">
                      <span>cm</span>
                      <Switch
                        onClick={() => setMeasureInches(!measureInches)}
                      />
                      <span>inch</span>
                    </div>

                    <div className="size-guide-table-title-cell">Diameter</div>
                    <div
                      className="size-guide-table-title-cell"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      Circumference
                    </div>
                  </div>

                  {ringSizes.map((data, index) => (
                    <div
                      className="size-guide-table-title-column"
                      key={index}
                      onClick={() => setSize(index)}
                      style={{
                        border: size === index ? "1px solid black" : "none",
                      }}
                    >
                      <div
                        className="size-guide-table-title-cell"
                        style={{
                          textAlign: "center",
                          height: "70px",
                          borderTop: "1px solid black",
                        }}
                      >
                        <div
                          className="size-guide-table-cell-circle"
                          style={{
                            border:
                              size === index
                                ? "5px solid black"
                                : "1px solid black",
                          }}
                        ></div>
                        <div> {data?.size} </div>
                      </div>

                      <div className="size-guide-table-title-cell">
                        {!measureInches ? data?.diameter : data?.diameterInches}
                      </div>
                      <div className="size-guide-table-title-cell">
                        {!measureInches
                          ? data?.circumference
                          : data?.circumferenceInches}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}

          <Divider style={{ background: "#444444" }} />
          <div>CONTACT US</div>
          <div style={{ marginBottom: "24px" }}>
            Still not sure what size to purchase? Email us at{" "}
            <a href="mailto:customers@losode.com" style={{ color: "#800000" }}>
              customers@losode.com
            </a>
          </div>
        </div>
      </Modal>

      <style jsx="true">
        {`
          .size-guide-modal {
            font-family: DomaineSansText-Light;
          }
          .size-guide-product-section {
            display: flex;
            flex-direction: column;
            gap: 3px;
          }
          .cart-page-bag-section-body {
            display: flex;
            flex-direction: column;
            background: white;
          }
          .cart-page-bag-section-body-item {
            background: white;
            position: relative;
            // margin-bottom: 24px;
            // padding-bottom: 24px;
            // border-bottom: 1px solid #a7a7a7;
          }
          .cart-page-bag-section-body-item-main {
            display: flex;
            gap: 16px;
          }
          .size-guide-product-section-image {
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            height: 115px;
            width: 90px;
          }
          .cart-page-bag-section-body-item-delete-btn {
            position: absolute;
            top: 0;
            right: 0;
            font-size: 20px;
            cursor: pointer;
          }
          .cart-page-bag-section-body-item-delete-btn:hover {
            color: #800000;
          }

          .size-guide-table-title-cell {
            padding: 10px;
            border: 1px solid black;
            border-top: 0;
            border-right: 0;
          }
          .cm-inch-container {
            display: flex;
            gap: 8px;
            justify-content: center;
            align-items: center;
            border: 0;
            border-bottom: 1px solid black;
            height: 70px;
          }

          .size-guide-table-cell-circle {
            width: 24px;
            height: 24px;
            border: 1px solid black;
            border-radius: 50%;
            margin: auto;
          }
          .size-guide-table-title-column {
            width: 150px;
            text-align: center;
          }
          .size-guide-modal ::-webkit-scrollbar {
            width: 20px;
          }
          .size-guide-modal ::-webkit-scrollbar-track {
            background-color: transparent;
          }
          .size-guide-modal ::-webkit-scrollbar-thumb {
            background-color: #d6dee1;
            border-radius: 20px;
            border: 6px solid transparent;
            background-clip: content-box;
          }
          .to-fit-table {
            border: 1px solid;
            width: 100%;
            text-align: center;
            margin-top: 8px;
          }
          .to-fit-table tr {
          }
          .to-fit-table th {
            width: 33%;
            border: 1px solid black;
            height: 60px;
            font-family: "DomaineSansText-Regular";
            font-weight: bold;
          }
          .to-fit-table td {
            border: 1px solid black;
            height: 45px;
          }

          .ring-size-table {
            table-layout: fixed;
            width: 100%;
            border-collapse: collapse;
            border: 1px solid #000;
          }

          .ring-size-table th,
          .ring-size-table td {
            border: 1px solid #000;
            text-align: center;
            font-size: 14px;
            padding: 8px 0;
          }

          .ring-size-table th {
            font-weight: 400;
          }

          .ring-size-table td {
            text-transform: uppercase;
          }

          .kaftan-table {
            width: 65rem;
            table-layout: fixed;
            border-collapse: collapse;
            border: 1px solid #000;
          }

          .kaftan-table thead th {
            background: #000;
            color: white;
          }

          @media (max-width: 640px) {
            .size-guide-table-title-column {
              width: 120px;
            }
          }
        `}
      </style>
    </>
  );
};

export default SizeGuideModalMobile;
