import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { userActionCreators } from "../../../redux/user";
import Banner from "./sections/BannerMen";
import ShopDesigner from "./sections/ShopDesignerMen";
import ShopShirt from "./sections/ShopShirt";
import Bestseller from "./sections/Bestseller";

const MensPageMobile = () => {
  const dispatch = useDispatch();

  const { setUserType } = bindActionCreators(userActionCreators, dispatch);

  useEffect(() => {
    setUserType("Men");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
    >
      <Banner />
      <ShopShirt />
      <Bestseller />
      <ShopDesigner />
      {/* <Brands /> */}
      {/* <Line /> */}
      {/* <RandomCategory /> */}
      {/* <StylistChoice /> */}
      {/* <Verdict /> */}
      {/* <Line /> */}
      {/* <Ads />
      
      <Quality /> */}
    </div>
  );
};

export default MensPageMobile;
