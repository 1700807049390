import { useState } from "react";
import { Checkbox, Collapse, Slider, InputNumber, Button, Input } from "antd";
import BrandDiv from "./BrandsDiv";
import { SearchOutlined } from "@ant-design/icons";
import colors from "../../assets/colors";
import formatter from "../../utils/formatter";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";

const { Panel } = Collapse;

const Filter = ({ title, options, checkOption, clearCheck, selectedOptions }) => {

  if (options && options.length === 0) return null;
  return (
    <div className="filter">
      <Collapse className="collapse-container" ghost expandIconPosition="right">
        <Panel
          className="panel"
          header={
            <div>
              <div>{title}</div>
              <div style={{ color: "#92959E", fontSize: "12px" }}>All</div>
            </div>
          }
          key="1"
        >
          <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
            {selectedOptions.length > 0 && (
              <div
                style={{
                  color: "#92959E",
                  fontSize: "12px",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
                onClick={() => {
                  clearCheck(title.toLowerCase());
                }}
              >
                Unselect all
              </div>
            )}
            {options?.map((option) => (
              <div className="option-item">
                <Checkbox
                  id={option}
                  style={{
                    fontSize: 12,
                    textTransform: "capitalize",
                  }}
                  checked={selectedOptions.includes(option)}
                  onClick={() => {
                    checkOption(option);
                  }}
                >
                  {option}
                </Checkbox>
              </div>
            ))}
          </div>
        </Panel>
      </Collapse>
    </div>
  );
};

const CategoryFilter = ({ categories, checkCategory, clearCheck, selectedOptions }) => {
  return (
    <div className="box" style={{ borderTop: "1px solid #e6e6e6" }}>
      <Collapse
        className="collapse-container"
        ghost
        expandIconPosition="right"
        // defaultActiveKey={["1"]}
      >
        <Panel
          className="panel"
          header={
            <div>
              <div>Category</div>
              <div
                style={{
                  color: "#92959E",
                  fontSize: "12px",
                }}
              >
                All
              </div>
            </div>
          }
          key="1"
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "8px",
            }}
          >
            {selectedOptions.length > 0 && (
            <div
              style={{
                fontSize: "12px",
                textDecoration: "underline",
                color: "#92959E",
                cursor: "pointer",
              }}
              onClick={() => {
                clearCheck("category");
              }}
            >
              Unselect all
            </div>
          )}
            {categories?.map((category) => (
              <div className="category-div category-filter-item">
                <Checkbox
                  style={{
                    fontSize: 12,
                    textTransform: "capitalize",
                  }}
                  checked={selectedOptions.includes(category.cat_id)}
                  onClick={() => {
                    checkCategory(category.cat_id, category.catname);
                  }}
                >
                  {category?.catname?.toLowerCase()}
                  {/* <span style={{ color: "#C4C4C4" }}>{category.size})</span> */}
                </Checkbox>
              </div>
            ))}
          </div>
        </Panel>
      </Collapse>
    </div>
  );
};

const BrandsFilter = ({ brands, checkBrands, clearCheck, selectedOptions }) => {
  return (
    <div className="box">
      <Collapse className="collapse-container" ghost expandIconPosition="right">
        <Panel
          className="panel"
          header={
            <div>
              <div>Designers</div>
              <div
                style={{
                  color: "#92959E",
                  fontSize: "12px",
                }}
              >
                All
              </div>
            </div>
          }
          key="1"
        >
          <Input
            style={{
              border: "1px solid #C4C4C4",
              fontSize: "14px",
              padding: "8px",
            }}
            allowClear
            className="searchBox"
            placeholder={`Search designers`}
            prefix={
              <SearchOutlined
                style={{ color: "#B7B7B7", marginRight: "8px" }}
              />
            }
          />
          {selectedOptions.length > 0 && (
            <div
              style={{
                fontSize: "12px",
                textDecoration: "underline",
                color: "#92959E",
                cursor: "pointer",
                padding: "8px 0",
              }}
              onClick={() => {
                clearCheck("brand");
              }}
            >
              Unselect all
            </div>
          )}

          <div className="brands-container">
            {
              <BrandDiv
                products={brands}
                checkBrand={checkBrands}
                selectedOptions={selectedOptions}
              />
            }
          </div>
        </Panel>
      </Collapse>
    </div>
  );
};

const ColorFilter = ({ products, checkColor, clearCheck, selectedOptions }) => {
  const colorArr = [];
  colors.map((color) => {
    if (products?.meta_data?.colors?.includes(color.name)) {
      colorArr.push(color);
    }
    return true;
  });

  colorArr.sort((a, b) => {
    if (a.name > b.name) return 1;
    if (a.name < b.name) return -1;
    return 0;
  });

  if (colorArr && colorArr.length === 0) return null;

  return (
    <div className="box">
      <Collapse
        className="collapse-container"
        ghost
        expandIconPosition="right"
        // defaultActiveKey={["1"]}
      >
        <Panel
          className="panel"
          header={
            <div>
              <div>Color</div>
            </div>
          }
          key="1"
        >
          {selectedOptions.length > 0 && (
            <div
              style={{
                fontSize: "12px",
                textDecoration: "underline",
                color: "#92959E",
                cursor: "pointer",
              }}
              onClick={() => {
                clearCheck("color");
              }}
            >
              Unselect all
            </div>
          )}
          <div className="color-row">
            <div
              className="size-filter-item"
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "8px",
              }}
            >
              {colorArr?.map((color) => (
                <Checkbox
                  id={color.id}
                  className="color-container"
                  checked={selectedOptions.includes(color.name.toLowerCase())}
                  value={color.name}
                  onClick={() => {
                    checkColor(color.name?.toLowerCase());
                  }}
                >
                  <div style={{ display: "flex" }}>
                    <div
                      className="color-box"
                      style={{ background: `${color.hex}` }}
                    />
                    <p style={{ fontSize: "12px", margin: 0 }}>{color.name}</p>
                  </div>
                </Checkbox>
              ))}
            </div>
          </div>
        </Panel>
      </Collapse>
    </div>
  );
};

const PriceFilter = ({ title, lPrice, hPrice, priceFilter, selectedOptions }) => {
  const isTabletOrMobile = useMediaQuery({ maxWidth: 640 });
  const [minPrice, setMinPrice] = useState(lPrice);
  const [maxPrice, setMaxPrice] = useState(hPrice);

  const { data } = useSelector((state) => state.currency);
  const { code, indicator, rate } = data
    ? data
    : { code: "", currency: "", rate: "" };

  const handlePriceChange = (values) => {
    setMinPrice(values[0]);
    setMaxPrice(values[1]);
    priceFilter(values);
  };

  const applyPriceFilter = () => {
    priceFilter([minPrice, maxPrice]);
  };

  return (
    <div className="filter">
      <Collapse className="collapse-container" ghost expandIconPosition="right">
        <Panel
          className="panel"
          header={
            <div>
              <div>{title}</div>
            </div>
          }
          key="1"
        >
          <Slider
            range
            defaultValue={[lPrice, hPrice]}
            min={lPrice}
            max={hPrice}
            onChangeComplete={handlePriceChange}
          />
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <div style={{ fontSize: "12px" }}>min</div>
              <InputNumber
                min={minPrice}
                max={maxPrice}
                step={1000}
                value={formatter(minPrice, rate, code, indicator)}
                style={{ fontSize: "12px", minWidth: "50px" }}
              />
            </div>
            <div>
              <div style={{ fontSize: "12px" }}>max</div>
              <InputNumber
                min={minPrice}
                max={maxPrice}
                step={1000}
                value={formatter(maxPrice, rate, code, indicator)}
                style={{ fontSize: "12px", minWidth: "50px" }}
              />
            </div>
          </div>
          {isTabletOrMobile && (
            <Button
              type="primary"
              size="small"
              onClick={applyPriceFilter}
              style={{ marginTop: "8px" }}
            >
              Apply
            </Button>
          )}
        </Panel>
      </Collapse>
    </div>
  );
};

const Filters = ({
  products,
  checkBrands,
  checkCategory,
  checkColor,
  checkOccasions,
  checkSize,
  checkFits,
  checkReturns,
  sellerIds,
  catIds,
  prodColors,
  prodSizes,
  price,
  prodFits,
  prodOccasions,
  prodReturns,
  priceFilter,
  cat,
  clearCheck,
  clearAll,
}) => {
  const categories = products?.categories || [];
  const brands = products?.brands || [];
  return (
    <div
      className="filter-section-container"
      style={{ fontFamily: "DomaineSansText-Light" }}
    >
      {/* Category Filter */}
      {cat !== "category" && (
        <CategoryFilter
          categories={categories}
          checkCategory={checkCategory}
          clearCheck={clearCheck}
          selectedOptions={catIds}
        />
      )}
      {/* brands Filter */}
      {!cat?.includes("brand") && (
        <BrandsFilter
          brands={brands}
          checkBrands={checkBrands}
          clearCheck={clearCheck}
          selectedOptions={sellerIds}
        />
      )}
      {/* Size Filter */}
      <div className="box">
        <Filter
          title="Size"
          options={products?.meta_data?.sizes}
          checkOption={checkSize}
          clearCheck={clearCheck}
          selectedOptions={prodSizes}
        />
      </div>
      {/* Occasion Filter */}
      <div className="box">
        <Filter
          title="Occasion"
          options={products?.meta_data?.occasions}
          checkOption={checkOccasions}
          clearCheck={clearCheck}
          selectedOptions={prodOccasions}
        />
      </div>
      {/* Fit Filter */}
      {!cat?.includes("shop/new/in") && (
        <div className="box">
          <Filter
            title="Fit"
            options={products?.meta_data?.fits}
            checkOption={checkFits}
            clearCheck={clearCheck}
            selectedOptions={prodFits}
          />
        </div>
      )}
      {/* Returns Filter */}
      {!cat?.includes("shop/new/in") && (
        <div className="box">
          <Filter
            title="Returns"
            options={products?.meta_data?.returns}
            checkOption={checkReturns}
            clearCheck={clearCheck}
            selectedOptions={prodReturns}
          />
        </div>
      )}
      {/* Color Filter */}
      <ColorFilter
        products={products}
        checkColor={checkColor}
        clearCheck={clearCheck}
        selectedOptions={prodColors}
      />
      {/* Price Filter */}
      <div className="box">
        {
          <PriceFilter
            title="Price"
            lPrice={products?.meta_data?.min_price}
            hPrice={products?.meta_data?.max_price}
            priceFilter={priceFilter}
            selectedOptions={price}
          />
        }
      </div>
    </div>
  );
};

export default Filters;
