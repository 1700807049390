//import { useMediaQuery } from "react-responsive";
import Banner from "./sections/Banner";
// import MobileSearchBar from "./sections/layout/MobileSearchBar";
import Ads from "./sections/Ads";
import ShopGender from "./sections/ShopGender";
// import Occasions from "./sections/Occasions";
// import Verdict from "./sections/Verdict";
import Quality from "./sections/Quality";
// import Cookies from "./sections/Cookies";
import DiscountModal from "./sections/DiscountModal";
import { useState } from "react";
import NewInHomepage from "./sections/NewInHomepage";
import Bestseller from "./sections/Bestseller";
import PopularCategory from "./sections/PopularCategory";

const HomePageMobile = () => {

  const [showDiscountModal, setShowDiscountModal] = useState(false);

  


  return (
    <div>
      <Banner />
      <NewInHomepage />
      <ShopGender />
      <Bestseller />
      <Ads />
      <PopularCategory />
      <Quality />
      {/* <Cookies showDiscountModal={showDiscountModal} /> */}
      <DiscountModal
        showDiscountModal={showDiscountModal}
        setShowDiscountModal={setShowDiscountModal}
      />
    </div>
  );
};

export default HomePageMobile;
