import { useNavigate } from "react-router-dom";
// import { useSelector } from "react-redux";
// import { Button } from "antd";

const PopularCategory = () => {
  const navigate = useNavigate();

  // const { type } = useSelector((state) => state.user);

  // const createCategoryURL = (cat, id) => {
  //   return(`/${type.toLowerCase()}/subcategory/${cat}/cid=${id}&page=1`);
  // };

  const brands = [
    {
      title:"Dresses",
      url: '/products/category/cid=11&name=women-dresses?page=1',
      imageUrl:"https://res.cloudinary.com/losode-ng/image/upload/f_auto/v1729591174/website-pictures-optimized/popular-category-mobile-dress_fjksza.png",
    },
    {
      title:"Tops",
      url: '/products/category/cid=16&name=women-tops-blouses?page=1',
      imageUrl:"https://res.cloudinary.com/losode-ng/image/upload/f_auto/v1729590808/website-pictures-optimized/popular-category-mobile-top_j3qutc.png",
    },
    {
      title:"Skin and Beauty",
      url: "/women/subcategory/beauty/cid=3?page=1",
      imageUrl:"https://res.cloudinary.com/losode-ng/image/upload/w_600/f_auto/v1729590763/website-pictures-optimized/popular-category-mobile-beauty_xhmus5.png",
    },
    {
      title:"Accessories",
      url: "/women/subcategory/accessories/cid=10?page=1",
      imageUrl:"https://res.cloudinary.com/losode-ng/image/upload/w_600/f_auto/v1729590728/website-pictures-optimized/popular-category-mobile-accessories_i3yd01.png",
    }
];
  return (
    <div className="popularcategory-container">
      <h1 className="popularcategory-title">Popular Categories</h1>

      <div className="popularcategory-products-container">
        {brands.map((brand, index) => (
          <div
            className="popularcategory-product"
            key={index}
            onClick={() => navigate(brand.url)}
          >
            <img
              className="popularcategory-product-image"
              loading="lazy"
              alt="brand"
              src={brand.imageUrl}
            />
           {/* <div className="popularcategory-product-title">{brand.title}</div> */}
           
          </div>
        ))}
      </div>

      <style jsx="true">{`
        .popularcategory-container {
          margin: 0;
          padding: 30px 100px;
          background: #f9f9f9;
        }
        .popularcategory-container .popularcategory-title {
          font-family: 'DomaineSansDisplay-SemiBold';
          font-size: 30px;
          text-align: center;
          line-height: 26px;
          margin: 0 auto 24px;
        }
        .popularcategory-products-container {
          display: grid;
          grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
          gap: 24px;
          justify-content: center;
          text-align: center;
        }
        .popularcategory-product{
          position:relative;
          cursor: pointer;
        }
        .popularcategory-product-image{
          width: 100%;
          height: auto;
        }
        .popularcategory-product-title{
          font-size: 18px;
          font-family: DomaineSansDisplay-SemiBold;
          line-height: 1;
          position:absolute;
          bottom: 14px;
          left: 10px;
          color: #fff;
        }
        .popularcategory-product-price{
          font-size: 12px;
          font-family: DomaineSansText-Medium;
          color: #000;
        }
        .mobile-popularcategory-btn {
          width: 100%;
          height: 40px;
          background: #000000;
          border-radius: 2px;
          color: #fff;
          font: normal normal 16px DomaineSansText-Regular;
          margin: 16px 0;
        }

      `}</style>
    </div>
  );
};

export default PopularCategory;
