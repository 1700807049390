import React from "react";
import { useMediaQuery } from "react-responsive";
import MailingListModalMobile from "./MailingListModalMobile";
import MailingListModalWeb from "./MailingListModalWeb";

function MailingListModal() {
  const isTabletOrMobile = useMediaQuery({ maxWidth: 640 });
  return (
    <>
      {isTabletOrMobile ? <MailingListModalMobile /> : <MailingListModalWeb />}
    </>
  );
}

export default MailingListModal;
