/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import {
  Button,
  Dropdown,
  Input,
  Modal,
  Popconfirm,
  Table,
  Tooltip,
} from "antd";
import { bindActionCreators } from "redux";
import { useDispatch } from "react-redux";
import { InfoCircleOutlined } from "@ant-design/icons";
import { useMediaQuery } from "react-responsive";
import standardizeColor from "../../../utils/standardizeColor";
import colors from "../../../assets/colors";
import sizes from "../../../assets/sizes";

import triggerNotification from "../../../hooks/triggerNotification";
import { storeActionCreators } from "../../../redux/stores";

const QuantityModalMobile = ({
  product,
  visible,
  setVisible,
  type,
  itemCat,
  itemSubCat,
}) => {
  const dispatch = useDispatch();
  const isTabletOrMobile = useMediaQuery({ maxWidth: 640 });

  const [variant, setVariant] = useState([]);
  const [recordId, setRecordId] = useState({});
  const [itemLength, setItemLength] = useState(0);

  const {
    updateProductVariant,
    updateDraftVariant,
    deleteProductVariant,
    deleteDraftVariant,
    fetchSingleDraft,
    fetchSingleProduct,
  } = bindActionCreators(storeActionCreators, dispatch);

  const {
    // category_name,
    // subcategory_name,
    // exclusive_group,
    main_cat,
    maincategory_name,
    product_id,
    // sub_cat,
    // group,
    name,
    quantity,
  } = product;

  const itemVariant = product?.varieties
    ? product?.varieties
    : product?.variety
    ? product?.variety
    : [{ name, quantity }];

  const newItem = {
    name,
    color: "color",
    size: "size",
    quantity: "",
  };

  const categorySize = sizes(
    maincategory_name || main_cat,
    itemSubCat,
    itemCat
  );

  // console.log({
  //   product,
  //   maincategory_name,
  //   main_cat,
  //   category_name,
  //   exclusive_group,
  //   group,
  //   subcategory_name,
  //   sub_cat,
  // });

  const handleSizeButtonClick = ({ key }) => {
    const size = categorySize?.find((size) => size.id.toString() === key);
    const record = variant.find((variant) => variant.index === recordId);
    record.size = size.letter;
    console.log([...variant]);
    setVariant([...variant]);
  };

  const handleColorButtonClick = ({ key }) => {
    const color = colors.find((color) => color.id.toString() === key);
    const record = variant.find((variant) => variant.index === recordId);
    record.color = color.name;
    setVariant([...variant]);
  };

  const sizeItems = categorySize?.map((item) => {
    return {
      key: item?.id,
      label: <div className="color-name">{item?.letter}</div>,
    };
  });

  const colorItems = colors.map((item) => {
    return {
      key: item?.id,
      // Use the `label` prop to display the item's name.
      label: <div className="color-name">{item?.name}</div>,
      // Use the `icon` prop to display the item's color.
      icon: <div style={{ background: item?.hex }} className="color-hex-box" />,
    };
  });

  const sizeProps = {
    items: sizeItems,
    onClick: handleSizeButtonClick,
  };
  const colorProps = {
    items: colorItems,
    onClick: handleColorButtonClick,
  };

  const columns = [
    {
      title: "Item name",
      dataIndex: "name",
      key: "name",
      width: 300,
    },
    {
      align: "center",
      title: "Size",
      dataIndex: "size",
      key: "size",
      render: (text, record) => (
        <div className="size-letter" onClick={() => setRecordId(record.index)}>
          {record.size}
          <Dropdown
            menu={sizeProps}
            trigger="click"
            overlayStyle={{ maxHeight: "300px", overflowY: "auto" }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="17"
              height="17"
              viewBox="0 0 17 17"
              fill="none"
            >
              <path
                d="M4.56641 6.5L8.56641 10.5L12.5664 6.5"
                stroke="black"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </Dropdown>
        </div>
      ),
      width: 300,
    },
    {
      align: "center",
      title: "Color",
      dataIndex: "colorObj",
      key: "color",
      render: (text, record) => (
        <div
          className="color-container"
          style={{ width: "130px", height: "45px" }}
          onClick={() => setRecordId(record.index)}
        >
          {!record?.color.includes("select") && (
            <div
              style={{ background: standardizeColor(record?.color) }}
              className="color-hex-box"
            />
          )}

          <div className="color-name">{record?.color}</div>
          <Dropdown
            menu={colorProps}
            trigger="click"
            overlayStyle={{ maxHeight: "300px", overflowY: "auto" }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="17"
              height="17"
              viewBox="0 0 17 17"
              fill="none"
            >
              <path
                d="M4.56641 6.5L8.56641 10.5L12.5664 6.5"
                stroke="black"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </Dropdown>
        </div>
      ),
      width: 300,
    },
    {
      align: "center",
      title: (
        <div style={{ display: "flex", gap: "8px", alignItems: "center" }}>
          <div>Quantity</div>
          <Tooltip title="Ensure online quantity matches offline availability at all times. If you sell an item offline please update item quantity ">
            <InfoCircleOutlined
              style={{
                color: "rgba(0,0,0,.45)",
                fontSize: "16px",
                marginRight: "20px",
              }}
            />
          </Tooltip>
        </div>
      ),
      dataIndex: "quantity",
      key: "quantity",
      render: (text, record) => (
        <div className="table-quantity-container">
          <Input
            className="input"
            type="number"
            min={1}
            defaultValue={record?.quantity ? record.quantity : ""}
            onChange={(e) => (record.quantity = e.target.value)}
            style={{ height: "45px", textAlign: "center", padding: 4 }}
          />
        </div>
      ),
    },
    {
      title: "",
      dataIndex: "key",
      render: (text, record) => {
        return record?.index < itemLength ? (
          <Popconfirm
            title="Deletions cannot be undone. Confirm delete?"
            onConfirm={() => confirm(record)}
            okText="Confirm"
            cancelText="Cancel"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="16"
              viewBox="0 0 18 16"
              fill="none"
            >
              <path
                d="M15.181 3.48732C14.021 3.38065 12.8611 3.30065 11.6939 3.24065V3.23398L11.5354 2.36732C11.4273 1.75398 11.2688 0.833984 9.58292 0.833984H7.69529C6.01659 0.833984 5.85809 1.71398 5.74281 2.36065L5.59151 3.21398C4.92147 3.25398 4.25144 3.29398 3.5814 3.35398L2.11164 3.48732C1.80904 3.51398 1.5929 3.76065 1.62172 4.03398C1.65054 4.30732 1.9099 4.50732 2.2125 4.48065L3.68226 4.34732C7.45753 4.00065 11.2616 4.13398 15.0801 4.48732C15.1017 4.48732 15.1161 4.48732 15.1378 4.48732C15.4115 4.48732 15.6493 4.29398 15.6781 4.03398C15.6997 3.76065 15.4836 3.51398 15.181 3.48732Z"
                fill="#FF0000"
              />
              <path
                d="M13.8539 5.42602C13.681 5.25935 13.4432 5.16602 13.1982 5.16602H4.09149C3.84653 5.16602 3.60157 5.25935 3.43587 5.42602C3.27016 5.59268 3.1765 5.81935 3.1909 6.05268L3.6376 12.8927C3.71685 13.906 3.81771 15.1727 6.33216 15.1727H10.9576C13.472 15.1727 13.5729 13.9127 13.6521 12.8927L14.0988 6.05935C14.1132 5.81935 14.0196 5.59268 13.8539 5.42602ZM9.84085 11.8327H7.44168C7.14629 11.8327 6.90133 11.606 6.90133 11.3327C6.90133 11.0593 7.14629 10.8327 7.44168 10.8327H9.84085C10.1362 10.8327 10.3812 11.0593 10.3812 11.3327C10.3812 11.606 10.1362 11.8327 9.84085 11.8327ZM10.446 9.16602H6.84369C6.5483 9.16602 6.30334 8.93935 6.30334 8.66602C6.30334 8.39268 6.5483 8.16602 6.84369 8.16602H10.446C10.7414 8.16602 10.9864 8.39268 10.9864 8.66602C10.9864 8.93935 10.7414 9.16602 10.446 9.16602Z"
                fill="#FF0000"
              />
            </svg>
          </Popconfirm>
        ) : (
          <div onClick={onAddVariant}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M12 5V19"
                stroke="black"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M5 12H19"
                stroke="black"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
        );
      },
    },
  ];

  const mobileColumns = [
    {
      title: "Item name",
      dataIndex: "name",
      key: "name",
      width: 300,
      render: (text, record) => (
        <p style={{ fontSize: "10px", width: "auto", marginBottom: "0px" }}>
          {product?.name}
        </p>
      ),
    },
    {
      align: "center",
      title: "Size",
      dataIndex: "size",
      key: "size",
      render: (text, record) => (
        <div
          className="size-letter"
          style={{
            width: "60px",
            height: "40px",
            fontSize: "9px",
          }}
          onClick={() => setRecordId(record.index)}
        >
          {record.size}
          <Dropdown
            menu={sizeProps}
            trigger="click"
            overlayStyle={{ maxHeight: "300px", overflowY: "auto" }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="17"
              height="17"
              viewBox="0 0 17 17"
              fill="none"
            >
              <path
                d="M4.56641 6.5L8.56641 10.5L12.5664 6.5"
                stroke="black"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </Dropdown>
        </div>
      ),
    },
    {
      align: "center",
      title: "Color",
      dataIndex: "colorObj",
      key: "color",
      render: (text, record) => (
        <div
          className="color-container"
          style={{
            width: "90px",
            height: "40px",
            fontSize: "10px",
            alignItems: "center",
          }}
          onClick={() => setRecordId(record.index)}
        >
          <div
            style={{ background: standardizeColor(record?.color) }}
            className="color-hex-box"
          />

          <div className="color-name">{record?.color}</div>
          <Dropdown
            menu={colorProps}
            trigger="click"
            overlayStyle={{ maxHeight: "300px", overflowY: "auto" }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="17"
              height="17"
              viewBox="0 0 17 17"
              fill="none"
            >
              <path
                d="M4.56641 6.5L8.56641 10.5L12.5664 6.5"
                stroke="black"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </Dropdown>
        </div>
      ),
    },
    {
      align: "center",
      title: (
        <div style={{ display: "flex", gap: "8px", alignItems: "center" }}>
          <div>Quantity</div>
        </div>
      ),
      dataIndex: "quantity",
      key: "quantity",
      render: (text, record) => (
        <div className="table-quantity-container">
          <Input
            className="input"
            type="number"
            min={1}
            defaultValue={record?.quantity ? record.quantity : ""}
            onChange={(e) => (record.quantity = e.target.value)}
            style={{ height: "40px", width: "40px", fontSize: "9px" }}
          />
        </div>
      ),
    },
    {
      align: "center",
      title: "",
      dataIndex: "key",
      render: (text, record) => {
        return record?.index < itemLength ? (
          <Popconfirm
            title="Deletions cannot be undone. Confirm delete?"
            onConfirm={() => confirm(record)}
            okText="Confirm"
            cancelText="Cancel"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="16"
              viewBox="0 0 18 16"
              fill="none"
            >
              <path
                d="M15.181 3.48732C14.021 3.38065 12.8611 3.30065 11.6939 3.24065V3.23398L11.5354 2.36732C11.4273 1.75398 11.2688 0.833984 9.58292 0.833984H7.69529C6.01659 0.833984 5.85809 1.71398 5.74281 2.36065L5.59151 3.21398C4.92147 3.25398 4.25144 3.29398 3.5814 3.35398L2.11164 3.48732C1.80904 3.51398 1.5929 3.76065 1.62172 4.03398C1.65054 4.30732 1.9099 4.50732 2.2125 4.48065L3.68226 4.34732C7.45753 4.00065 11.2616 4.13398 15.0801 4.48732C15.1017 4.48732 15.1161 4.48732 15.1378 4.48732C15.4115 4.48732 15.6493 4.29398 15.6781 4.03398C15.6997 3.76065 15.4836 3.51398 15.181 3.48732Z"
                fill="#FF0000"
              />
              <path
                d="M13.8539 5.42602C13.681 5.25935 13.4432 5.16602 13.1982 5.16602H4.09149C3.84653 5.16602 3.60157 5.25935 3.43587 5.42602C3.27016 5.59268 3.1765 5.81935 3.1909 6.05268L3.6376 12.8927C3.71685 13.906 3.81771 15.1727 6.33216 15.1727H10.9576C13.472 15.1727 13.5729 13.9127 13.6521 12.8927L14.0988 6.05935C14.1132 5.81935 14.0196 5.59268 13.8539 5.42602ZM9.84085 11.8327H7.44168C7.14629 11.8327 6.90133 11.606 6.90133 11.3327C6.90133 11.0593 7.14629 10.8327 7.44168 10.8327H9.84085C10.1362 10.8327 10.3812 11.0593 10.3812 11.3327C10.3812 11.606 10.1362 11.8327 9.84085 11.8327ZM10.446 9.16602H6.84369C6.5483 9.16602 6.30334 8.93935 6.30334 8.66602C6.30334 8.39268 6.5483 8.16602 6.84369 8.16602H10.446C10.7414 8.16602 10.9864 8.39268 10.9864 8.66602C10.9864 8.93935 10.7414 9.16602 10.446 9.16602Z"
                fill="#FF0000"
              />
            </svg>
          </Popconfirm>
        ) : (
          <div onClick={onAddVariant}>
            <svg
              width="24"
              height="24"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16 29.3327C23.3638 29.3327 29.3333 23.3631 29.3333 15.9993C29.3333 8.63555 23.3638 2.66602 16 2.66602C8.63616 2.66602 2.66663 8.63555 2.66663 15.9993C2.66663 23.3631 8.63616 29.3327 16 29.3327Z"
                stroke="black"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M16 10.666V21.3327"
                stroke="black"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M10.6666 16H21.3333"
                stroke="black"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
        );
      },
    },
  ];

  const noVarColumns = [
    {
      title: "Item name",
      dataIndex: "name",
      key: "name",
      width: 300,
    },

    {
      align: "center",
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      render: (text, record) => (
        <div className="table-quantity-container">
          <Input
            className="input"
            type="number"
            min={1}
            defaultValue={record?.quantity ? record.quantity : ""}
            onChange={(e) => (record.quantity = e.target.value)}
            style={{ height: "45px", textAlign: "center", padding: 4 }}
          />
        </div>
      ),
    },
  ];

  const loadVariants = () => {
    itemVariant?.map((val, index) => {
      val.name = name;
      val.index = index + 1;
      return val;
    });
    setVariant([
      ...itemVariant,
      { ...newItem, index: itemVariant?.length + 1 },
    ]);
    setItemLength(itemVariant.length + 1);
  };

  const onAddVariant = () => {
    setVariant([...variant, { ...newItem, index: variant.length + 1 }]);
    setItemLength(itemLength + 1);
  };

  const onUpdate = async () => {
    const values = {
      product_id,
      variety: variant.filter((item) => item.quantity !== ""),
    };
    const res =
      type === "draft"
        ? await updateDraftVariant(values)
        : await updateProductVariant(values);
    if (res.status === "ok") {
      setVisible(false);
      triggerNotification({
        type: "success",
        message: res.message,
      });
      type === "draft"
        ? await fetchSingleDraft(product_id)
        : await fetchSingleProduct(product_id);
    }
    if (res.status === "not ok") {
      triggerNotification({
        type: "error",
        message: res.message,
      });
    }
  };

  const confirm = async ({ id, index }) => {
    if (id) {
      const res =
        type === "draft"
          ? await deleteDraftVariant(id, product_id)
          : await deleteProductVariant(id, product_id);
      if (res.status === "ok") {
        const data = variant.filter((item) => item.id !== id);
        setVariant(data);
        setVisible(false);
        triggerNotification({
          type: "success",
          message: res.message,
        });
      }

      if (res.status === "not ok") {
        triggerNotification({
          type: "error",
          message: res.message,
        });
      }
    } else {
      setVariant(variant.filter((val) => val.index !== index));
    }
  };

  useEffect(() => {
    loadVariants();
  }, [product]);

  return (
    <Modal
      centered
      open={visible}
      footer={null}
      onCancel={() => setVisible(false)}
      width={!isTabletOrMobile ? "55%" : "100%"}
      style={{ padding: `${!isTabletOrMobile ? 0 : 10}` }}
      maskClosable={false}
      closable={false}
      className="quantity-modal"
    >
      {product?.varieties?.length > 0 || product?.variety?.length > 0 ? (
        <Table
          dataSource={variant}
          columns={!isTabletOrMobile ? columns : mobileColumns}
          pagination={false}
          className="preview-variety-table"
        />
      ) : (
        <Table dataSource={variant} columns={noVarColumns} pagination={false} />
      )}

      <div className="buttons-container">
        <Button className="action-btn" onClick={() => setVisible(false)}>
          Cancel
        </Button>
        <Button
          className="action-btn"
          type="primary"
          onClick={() => onUpdate()}
        >
          {!isTabletOrMobile ? "Confirm" : "Update Quantity"}
        </Button>
      </div>

      <style jsx="true">
        {`
          .quantity-modal {
            background: #fff;
            font-family: "DomaineSansText-Light";
          }

          .variant-item-name,
          .listing-name {
            width: 110px;
          }
          .ant-modal-centered .ant-modal {
            padding: 50px 30px;
          }
          .ant-table-thead > tr > th {
            background-color: #000;
            border: 0px;
            color: #fff;
          }

          .ant-modal-content {
            box-shadow: 0px 0px;
          }

          .size-letter {
            border: 1px solid black;
            text-align: center;
            height: 45px;
            display: flex;
            gap: 10px;
            align-items: center;
            justify-content: center;
            padding: 10px;
          }

          .color-container {
            padding: 8px;
            border: 1px solid black;
            width: 120px;
            display: flex;
            align-items: center;
            gap: 10px;
            justify-content: center;
            white-space: nowrap;
            cursor: pointer;
            margin: 0px auto;
          }
          .color-hex-box {
            width: 12px;
            height: 12px;
            border-radius: 50%;
          }
          .table-quantity-container {
            display: flex;
            align-items: center;
            margin: 0 auto;
            gap: 20px;
            width: 86px;
          }
          .table-quantity-delete-icon {
            cursor: pointer;
          }

          .buttons-container {
            display: flex;
            gap: 60px;
            margin-top: 40px;
            justify-content: flex-end;
          }
          .action-btn {
            width: 200px;
            height: 50px;
          }

          .quantity-modal svg {
            cursor: pointer;
          }

          .quantity-modal
            .ant-select-single:not(.ant-select-customize-input)
            .ant-select-selector {
            margin: 0 auto;
            height: 45px;
            padding: 6px;
          }

          @media (max-width: 640px) {
            .ant-modal-body {
              padding: 4px;
            }

            .ant-modal-centered .ant-modal {
              padding: 0px;
            }
            .ant-table-thead > tr > th,
            .ant-table-tbody > tr > td,
            .ant-table tfoot > tr > th,
            .ant-table tfoot > tr > td {
              padding: 10px;
            }

            .size-letter {
              font-size: 10px;
              width: 90px;
            }

            .color-container {
              padding: 4px;
              gap: 5px;
            }
            .color-hex-box {
              width: 8px;
              height: 8px;
              border-radius: 50%;
            }
            .ant-dropdown-menu-item-icon {
              min-width: 8px;
            }

            .table-quantity-container {
              width: auto;
              margin: 0 auto;
            }
            .preview-variety-table .ant-table-thead > tr > th {
              font-size: 9px;
              padding: 6px;
              text-align: center;
            }
            .preview-variety-table .ant-table-thead > tr > th:first-child {
              text-align: left;
            }
            .preview-variety-table .ant-table-tbody > tr > td {
              font-size: 9px;
              padding-top: 6px;
              padding-bottom: 6px;
            }
            .preview-variety-table .ant-table-tbody > tr > td:first-child {
              padding-right: 0px;
              padding-left: 0px;
            }
            .product-color-section-container {
              gap: 4px !important;
              flex-direction: column;
            }

            .buttons-container {
              flex-direction: column-reverse;
              justify-content: center;
              align-items: center;
              gap: 20px;
              margin-bottom: 20px;
            }
            .action-btn {
              width: 80%;
              font-size: 16px;
            }
          }
        `}
      </style>
    </Modal>
  );
};

export default QuantityModalMobile;
