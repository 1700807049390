import React, { useState, useEffect } from "react";
import { Button } from "antd";
import { Link } from "react-router-dom";

const Cookies = ({ showDiscountModal }) => {
  const [showCookies, setShowCookies] = useState(false);

  const closeCookies = () => {
    setShowCookies(false);
    window.localStorage.setItem("acceptCookies", "false");
  };

  const acceptCookies = () => {
    setShowCookies(false);
    window.localStorage.setItem("acceptCookies", "true");
  };

  useEffect(() => {
    if (window.localStorage.getItem("acceptCookies") === "true") {
      return setShowCookies(false);
    }
    setShowCookies(true);
  }, []);

  return (
    <>
      {showCookies && !showDiscountModal && (
        <div className="cookies-container">
          <div className="cookies-content">
            <div className="cookies-header">
              <h1 className="cookies-heading">cookies and privacy</h1>
              <div onClick={closeCookies} style={{ cursor: "pointer" }}>
                <svg
                  width="25"
                  height="25"
                  viewBox="0 0 33 33"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M24.75 8.25L8.25 24.75"
                    stroke="black"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M8.25 8.25L24.75 24.75"
                    stroke="black"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
            <p className="cookies-text-1">
              Our site uses cookies and similar technologies to offer you a
              better experience. We use analytical cookies (our own and third
              party) to understand and improve your browsing experience, and
              advertising cookies (our own and third party) to send you
              advertisements in line with your preferences.
            </p>
            <p className="cookies-text-2">
              To modify or opt-out of the use of somecookies-container or all of
              our cookies, please go to our{" "}
              <Link to="/policy" style={{ textDecoration: "underline" }}>
                Cookie Policy
              </Link>{" "}
              to find out more. By clicking “Accept” you consent to the use
              of these cookies.
            </p>
          </div>
          <div className="cookies-button">
            <Button className="cookies-btn accept-btn" onClick={acceptCookies}>
              Accept
            </Button>
          </div>
          <style jsx="true">{`
          .cookies-container {
            position: fixed;
            bottom: 14px;
            left: 50%;
            transform: translateX(-50%);
            background-color: #fff;
            width: 90%;
            padding: 1.5rem 3rem;
            box-shadow: 0px 4px 17.8px 0px #00000040;
            z-index: 1000;
            max-width: 1360px;
          }
          .cookies-header {
            display: flex;
            align-items center;
            justify-content: space-between;
            margin-bottom: 5px;
          }
          .cookies-heading {
            font: normal 600 18px DomaineSansText-Regular;
            letter-spacing: 0.28px;
            color: #000000;
            text-transform: uppercase;
            margin-bottom: 0.5rem;
            padding: 0;
          }
          .cookies-container p {
            font: normal 300 14px DomaineSansText-Light;
            line-height: 23px;
            color: #000;
            margin: 3px 0;
          }
          .cookies-line {
            height: 1px;
            width: 100%;
          }
          .cookies-content {
            padding-bottom: 0.5rem;
            border-bottom: 1px solid #6f6f6f;
          }
          .cookies-button {
            display: flex;
            justify-content: flex-end;
            margin-top: 1rem;
          }
          .cookies-btn { 
            width: 300px;
            height: 45px;
            font: normal 400 16px DomaineSansText-Light;
            color: #000;
            border-radius: 2px;
          }
          .cookies-btn.accept-btn {
            background-color: #000;
            color: #fff;
            margin-left: 1rem;
          }
          .cookies-btn.accept-btn:hover {
            background-color: #fff;
            color: #000;
          }
         .cookies-text-2 {
            padding-top: 10px;
         }
          

          @media (min-width: 768px) and (max-width: 1024px) {
            .cookies-button {
              display: flex;
              justify-content: space-around;
            }
            .cookies-btn {
              width: 300px;
              height: 48px;
            }
            .cookies-btn.accept-btn {
              margin-left: 0;
            }
            .cookies-text-2 {
              padding-top: 5px;
            }
          }

          @media (max-width: 640px) {
            .cookies-container {
              padding: 1rem;
              width: 95%;
            }
            .cookies-text-1 {
              padding-bottom: 0.5rem;
            }
            .cookies-button {
              display: flex;
              flex-direction: column;
              justify-content: center;
              margin-top: 1rem;
            }
            .cookies-btn {
              width: 100%;
            }
            .cookies-btn.accept-btn {
              margin-left: 0;
              margin-top: 0rem;
            }
          }
        `}</style>
        </div>
      )}
    </>
  );
};

export default Cookies;
