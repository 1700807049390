import { useMediaQuery } from "react-responsive";
import WomensPageMobile from "./womensPageMobile";
import WomensPageWeb from "./womensPageWeb";

const WomensPage = () => {
  const isTabletOrMobile = useMediaQuery({ maxWidth: 480 });

  return <>{isTabletOrMobile ? <WomensPageMobile /> : <WomensPageWeb />}</>;
};

export default WomensPage;
