import { createRef } from "react";
import { Carousel } from "antd";

const Quality = () => {

  const carouselSettings = {
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    // dots: false,
    infinite: false,
  };

  const carouselRef = createRef();

  const items = [
    {
      title: "Quick & Secure Deliveries",
      desc: "We have taken the utmost care in defining efficient logistics processes ",
      url: "/images/icon-truck.svg",
    },
    {
      title: "Fair Returns Policy",
      desc: "Our designers have fair policies in place to protect our customers",
      url: "/images/icon-returns.svg",
    },
    {
      title: "Easy to Use Platform",
      desc: "We have developed a platform that you can navigate with ease ",
      url: "/images/icon-search.svg",
    },
    {
      title: "24/7 Support",
      desc: "Visit our contact us page for all the ways to reach us",
      url: "/images/icon-support.svg",
    },
  ];

  return (
    <div className="quality-container">
      <h1 className="quality-title">Quality remains our utmost priority</h1>
      <h4 className="quality-desc">
        ‘What you see is definitely, what you get'. <br/>
        We understand how important this is to our customers and our business. We have put measures in place to ensure every purchase exceeds your expectations. If not we will rectify the situation quickly and with ease. 
      </h4>
      
      <div className="quality-line-container">
        <span className="quality-line-span" />
      </div>

      <div style={{ marginTop: "32px", marginBottom: "40px" }}>
        <Carousel {...carouselSettings} ref={carouselRef} >
          {items.map((item, idx) => (
            <div>
              <div className="quality-item-div" key={idx}>
                <img
                  className="quality-icon"
                  alt={item.title}
                  src={item.url}
                />
                <h1 className="quality-item-title">{item.title}</h1>
                <h4 className="quality-item-desc">{item.desc}</h4>
              </div>
            </div>
          ))}
        </Carousel>
      </div>


      <style jsx="true">{`
        .quality-container {
          padding: 16px;
          text-align: center;
        }
        .quality-title {
          font-family: "DomaineSansText-Medium";
          font-size: 16px;
          margin-bottom: 6px;
          text-transform: uppercase;
        }
        .quality-desc {
          font: normal 300 14px DomaineSansText-Light;
          line-height: 18px;
          margin: 0 auto;
          padding: 0 8px;
        }
        .quality-item-div {
          align-items: center;
          display: flex;
          flex-direction: column;
        }
        .quality-icon {
          width: 32px;
          height: 32px;
        }
        .quality-item-title {
          font: normal 700 18px DomaineSansText-Black;
          line-height: normal;
          text-align: center;
          margin-top: 8px;
          text-transform: capitalize;
        }
        .quality-item-desc {
          font: normal 300 16px DomaineSansText-Regular;
          line-height: normal;
          text-align: center;
        }
        .quality-line-container {
        }

        .quality-line-span {
          display: block;
          border-bottom: 0.5px solid #ccc;
          width: 100%;
          margin: 8px auto;
        }

        .quality-container .ant-carousel .slick-dots-bottom button{
            height: 10px;
            width: 10px;
            background: #656565;
            border-radius: 50%;
          }
          .quality-container .ant-carousel .slick-dots li.slick-active button{
            background: black;
          }
          .quality-container .ant-carousel .slick-dots-bottom button:before{
            display: none;
          }
          
          .quality-container .ant-carousel .slick-dots-bottom {
            bottom: -20px !important;
          }

          .quality-container .ant-carousel .slick-dots li.slick-active{
            width: 16px;
          }

        
      `}</style>
    </div>
  );
};

export default Quality;
