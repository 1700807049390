import { Button, Carousel } from "antd";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const BannerWomen = () => {
  const { type } = useSelector((state) => state.user);

  const navigate = useNavigate();

  const shopNewIn = () => {
    if (type === "Men")
      navigate(`/new-in/men/cid=${1}&name=new-in-men-products`);
    if (type === "Women")
      navigate(`/new-in/women/cid=${2}&name=new-in-women-products`);
  };

  return (
    <div className="banner-container">
      <Carousel fade autoplay autoplaySpeed={5000} draggable speed={1200}>
        <img
          src="https://res.cloudinary.com/losode-ng/image/upload/f_auto/v1732084550/mens-homepage-image-1_rv98p2.png"
          alt="banner"
          className="bannercontainer-image"
        />
        <img
          src="https://res.cloudinary.com/losode-ng/image/upload/f_auto/v1732084554/mens-homepage-image-2_savvyr.png"
          alt="banner"
          className="bannercontainer-image"
        />
      </Carousel>

      <div className="banner-content-container">
        <div className="banner-content-title">
          Massive Savings from 100s’ of Designers
        </div>
        <div className="subtext">
          Style guaranteed to make you stand out at affordable prices
        </div>
        <Button
          type="link"
          onClick={() => shopNewIn()}
          className="shop-now-btn"
        >
          <span style={{ textDecoration: "underline" }}>Shop now</span>
        </Button>
      </div>

      <style jsx="true">{`
        .banner-container {
          position: relative;
          background-position: top;
        }
        .bannercontainer-image-container {
          display: flex;
          justify-content: space-between;
          width: 100%;
          gap: 0px;
        }
        .bannercontainer-image {
          flex: 1;
          height: 542px;
          width: 100%;
          object-fit: cover;
          object-position: top;
        }
        .banner-container .ant-carousel .slick-dots-bottom li button {
          bottom: 14px;
          height: 10px;
          border-radius: 130px;
          width: 10px;
          opacity: 0.5;
        }
        .banner-container .ant-carousel .slick-dots li.slick-active button {
          width: 20px;
          opacity: 1;
        }
        .banner-container .ant-carousel .slick-dots-bottom li button:before {
          display: none;
        }
        .banner-content-container {
          margin: auto;
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          text-align: center;
          bottom: 0px;
          left: 0px;
          padding: 16px 16px 0;
        }
        .banner-content-container .banner-content-title {
          font-family: "DomaineSansDisplay-SemiBold";
          font-size: 30px;
          text-align: center;
          line-height: 30px;
          margin: 8px auto;
        }
        .banner-content-container .subtext {
          font-size: 14px;
          font-family: DomaineSansText-Light;
          line-height: 18px;
        }

        .banner-content-container .shop-now-btn {
          color: rgb(0, 0, 0);
          font-family: "DomaineSansText-Medium";
          padding: 0px;
          height: auto;
          font-size: 14px;
          margin-top: 8px;
          text-transform: capitalize;
        }
      `}</style>
    </div>
  );
};

export default BannerWomen;
